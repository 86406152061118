import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { ServiceService } from 'src/app/service.service';

@Component({
  selector: 'app-staking-view',
  templateUrl: './staking-view.component.html',
  styleUrls: ['./staking-view.component.css']
})
export class StakingViewComponent implements OnInit {

  id: number;
  ticketDetail: any;
  _id: any;
  details: any = [];

  constructor(private route: ActivatedRoute, private router: Router, public service: ServiceService) {
    this.route.queryParams.subscribe((params) => {
      this.id = params.id;
      this._id = params.id
    });
  }

  ngOnInit(): void {
    // this.viewTicket();
    // this.showUserDetail()
    this.viewStaking();
  }

  viewStaking() {
    let url = `admin/stakeInterestView?interestId=${this._id}`
    this.service.showSpinner();
    this.service.getApi(url, 1).subscribe((res) => {
      if (res['responseCode'] == 200) {
        this.service.hideSpinner();
        this.service.toasterSucc(res['responseMessage']);
        this.details = res['result']
        console.log(this.details);

      } else {
        this.service.hideSpinner();
        this.service.toasterErr(res['responseMessage']);
      }
    }, (err) => {
      this.service.hideSpinner();
      this.service.toasterErr(err.error.responseMessage);
    })
  }
  viewTicket() {
    this.service.get(`stripe/payment/get-payment-byId?userId=${this.id}`).subscribe((res: any) => {
      this.ticketDetail = res.data

    })
  }
  // reply(){
  //   let a = document.createElement('a')
  //   a.href = `mailto:${this.ticketDetail.email}?body=Hello! ${this.ticketDetail.firstName}`
  //   a.click()
  // }
  userDetail: any
  // api of general information
  showUserDetail() {
    this.service.showSpinner();
    var url = "account/admin/user-management/user-details?userId=" + this.id;
    this.service.get(url).subscribe(
      (res: any) => {
        if (res.status == 200) {
          this.userDetail = res.data;
          this.service.hideSpinner();
        }
      },
      (err) => {
        this.service.hideSpinner();
        if (err["status"] == "401") {
          this.service.onLogout();
          this.service.toasterErr("Unauthorized Access");
        } else {
          this.service.toasterErr("Something Went Wrong");
        }
      }
    );
  }
}
