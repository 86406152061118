import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { FormGroup, FormControl, Validators } from '@angular/forms';
import { ServiceService } from 'src/app/service.service';
declare var $: any
@Component({
  selector: 'app-feedback-list',
  templateUrl: './feedback-list.component.html',
  styleUrls: ['./feedback-list.component.css']
})
export class FeedbackListComponent implements OnInit {
  selected: string = "btc";
  coinlist: any = [];
  coinListArr: any = [];
  transferForm: FormGroup;
  loopData: any = [];
  txndata: any = []
  lengthTotal: any;
  minDate: any;
  itemsPerPage = 10;
  currentPage: number = 1;
  totalItems: any
  flip: string = "List";

  today: any = new Date().toISOString().slice(0, 16)

  constructor(
    private router: Router,
    public service: ServiceService
  ) { }

  ngOnInit() {
    this.checkTransferFormValidations();
    // this.sidemenu();
    this.getAllFeedbackList()

  }

  copyToClipboard(item) {

    this.service.toasterSucc('Copied')
    document.addEventListener('copy', (e: ClipboardEvent) => {
      e.clipboardData.setData('text/plain', (item));
      e.preventDefault();
      document.removeEventListener('copy', null);
    });
    document.execCommand('copy');
  }
  pagination(page) {

    this.currentPage = page

    this.getAllFeedbackList()


  }
  checkTransferFormValidations() {
    this.transferForm = new FormGroup({
      'fromDate': new FormControl(''),
      'toDate': new FormControl(''),
      'amount': new FormControl(''),
      'status': new FormControl('')
      // 'currency': new FormControl(''),
      // 'type': new FormControl(''),
      //   'searchTextTxnHash': new FormControl(''),
      //   'coinNameSe' : new FormControl(''),
    })
  }
  ekFunctionZindgiKa() {

    let amount = this.transferForm.value.amount;
    let status = this.transferForm.value.status
    let url = `account/admin/get-user-feedback-list?page=${this.currentPage - 1}&pageSize=${this.itemsPerPage}${this.transferForm.value.amount ? "&feedbackUserId=" + amount : ""}${this.transferForm.value.status ? "&feedbackStatus=" + status : ""}`
    //  let zindgi  =  `wallet/admin/transaction-history/get-all-transaction-history?amount=${this.transferForm.value.amount}&coinName=${this.transferForm.value.coinNameSe}&fromDate=${Math.round(new Date(this.transferForm.value.fromDate).getTime())}&page=${this.currentPage - 1}&pageSize=${this.itemsPerPage}&status=${this.transferForm.value.status}&toDate=${Math.round(new Date(this.transferForm.value.toDate).getTime())}&txnHash=${this.transferForm.value.searchTextTxnHash}&txnType=${this.transferForm.value.type}`
    this.service.showSpinner();
    this.service.get(url).subscribe(
      (res: any) => {
        if (res.status == 200) {
          this.loopData = res.data;
          this.lengthTotal = res['data'].totalCount
          //  this.totalRecords = res.data.totalCount;
          this.service.hideSpinner();
        } else {
          this.service.hideSpinner()
        }


      },
      (err: any) => {
        this.service.hideSpinner();
        this.service.toasterErr(err.error.message);
      }
    );
  }

  reset() {
    if (
      this.transferForm.value.amount ||
      this.transferForm.value.status ||
      this.transferForm.value.fromDate ||
      this.transferForm.value.toDate

    ) {
      this.transferForm.reset({

        amount: "",
        status: "",
        fromDate: "",
        toDate: ""
      });
      this.getAllFeedbackList();
    }
  }





  getAllFeedbackList() {
    let fromDate = this.transferForm.value.fromDate;
    let toDate = this.transferForm.value.toDate;
    this.service.showSpinner()
    // this.loopData = []
    // this.lengthTotal = 0;
    this.service.get(`contactUs/listFeedback?page=${this.currentPage}&limit=${this.itemsPerPage}${this.transferForm.value.fromDate ? '&fromDate=' + new Date(fromDate).toISOString() : ""}${this.transferForm.value.toDate ? '&toDate=' + new Date(toDate).toISOString() : ""}`).subscribe((res: any) => {
      if (res.responseCode == 200) {
        this.service.hideSpinner()
        this.loopData = res.result.docs;
        this.lengthTotal = res.result.total
      }


    }, err => {
      this.service.hideSpinner();
      if (err.responseCode >= 400) {
        this.service.toasterErr(err.message)
      }
    })

  }
  // getAllFeedbackList() {
  //   this.service.showSpinner()
  //   // this.loopData = []
  //   // this.lengthTotal = 0;
  //   this.service.get(`account/get-feedback-list?page=${this.currentPage - 1}&pageSize=${this.itemsPerPage}`).subscribe((res:any)=>{
  //     this.service.hideSpinner()
  //     this.loopData = res.data;
  //     this.lengthTotal = res['data'].totalCount

  //   },err =>{
  //     this.service.hideSpinner();
  //     if(err.status >= 400){
  //       this.service.toasterErr(err.message)
  //     }
  //   })

  // }

}

