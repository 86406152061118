import { Component, OnInit } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { ServiceService } from '../service.service';

@Component({
  selector: 'app-tutorial-edit',
  templateUrl: './tutorial-edit.component.html',
  styleUrls: ['./tutorial-edit.component.css']
})
export class TutorialEditComponent implements OnInit {

  addSwatchesForm: FormGroup
  imageUrl: any
  bannerId: any;
  editData: any;
  itemPerPage = 10;
  currentPage = 1;
  totalItems: any;
  colorListArray: any = []
  showImageField: any = [];
  // bannerId: any;
  constructor(private router: Router, public commonService: ServiceService, public active: ActivatedRoute) {
    this.active.queryParams.subscribe((res: any) => {
      this.bannerId = res.bannerId;


    })
  }

  ngOnInit() {
    this.addSwatchesFormValidation();
    this.viewBlog()
  }
  addSwatchesFormValidation() {
    this.addSwatchesForm = new FormGroup({
      // 'colorName': new FormControl('', [Validators.required]),
      // 'author': new FormControl('', [Validators.required, Validators.pattern(/^[A-Za-z][A-Za-z ]*$/)]),
      'title': new FormControl('', [Validators.required, Validators.pattern(/^[A-Za-z][A-Za-z ]*$/)]),
      'description': new FormControl('', [Validators.required]),
      'image': new FormControl('')

      // 'description': new FormControl('',[Validators.required]),
      // 'file': new FormControl(''),
    })
  }



  imgfd: any

  addSwatches() {
    let url = `admin/editTutorial`


    let fb = new FormData()
    fb.append('title', this.addSwatchesForm.value.title)
    fb.append('description', this.addSwatchesForm.value.description)
    fb.append('tutorialId', this.bannerId)
    fb.append('mediaUrl', this.imgg)

    this.commonService.showSpinner();
    this.commonService.putFormApi(url, fb, 1).subscribe((res: any) => {
      console.log("add category response ==>", res)
      if (res.responseCode == 200) {
        this.commonService.hideSpinner();
        this.commonService.toasterSucc(res.responseMessage);
        this.router.navigate(['/tutorial-list'])
      } else {
        this.commonService.hideSpinner();
        this.commonService.toasterErr(res.responseMessage)
      }
    }, err => {
      this.commonService.hideSpinner();
      this.commonService.toasterErr(err.error.responseMessage)
    })
  }

  imgg: any
  uploadImgg1($event): void {
    this.imgg = $event.target.files[0];
  }



  viewBlog() {
    this.commonService.showSpinner()
    this.commonService.get("admin/viewTutorial?tutorialId=" + this.bannerId).subscribe((res: any) => {
      if (res.responseCode = 200) {
        this.editData = res.result;
        this.commonService.hideSpinner()
        this.addSwatchesForm.patchValue({
          'description': this.editData.description,
          'title': this.editData.title
        })
        this.imageUrl = this.editData.mediaUrl

      }
    }, err => {

      this.commonService.hideSpinner();
      if (err['responseCode'] == '401') {
        //  this.commonService.onLogout();
        this.commonService.toasterErr('Unauthorized Access');
      } else {
        this.commonService.toasterErr('Something Went Wrong');
      }
    })

  }


  getFileType(url) {
    return url.substring(url.lastIndexOf("."))
  }

  async uploadImg1($event) {
    this.imageUrl = await this.commonService.getBase64($event.target.files[0]);
  }


  // Image Functionality Start Here
  uploadImg($event): void {
    var img = $event.target.files[0];
    this.uploadImageFunc(img);
  }
  uploadImageFunc(img) {
    var fb = new FormData();
    fb.append('file', img)
    this.commonService.showSpinner();
    this.commonService.postApi('account/upload-file', fb).subscribe(res => {
      // this.commonService.hideSpinner();
      if (res['status'] == '200') {
        this.imageUrl = res['data'];
        this.commonService.hideSpinner();
      }
    }, err => {
      this.commonService.hideSpinner();
      if (err['status'] == '401') {
        this.commonService.onLogout();
        this.commonService.toasterErr('Unauthorized Access');
      } else {
        this.commonService.toasterErr('Something Went Wrong');
      }
    })
  }

}
