import { Component, OnInit } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { ServiceService } from '../service.service';

@Component({
  selector: 'app-my-profile-contact-update',
  templateUrl: './my-profile-contact-update.component.html',
  styleUrls: ['./my-profile-contact-update.component.css']
})
export class MyProfileContactUpdateComponent implements OnInit {

  userDetail: any = {};
  editForm: FormGroup;
  editImage: any;
  imageUrl: any

  constructor(public router: Router, public service: ServiceService) { }

  ngOnInit() {
    this.myProfile();
    this.contactValidtion();
  }

  reg = '(https?://)?([\da-z.-]+)\.([a-z.]{2,6})[/\w .-]*/?';

  contactValidtion() {
    this.editForm = new FormGroup({
      'mobileNumber': new FormControl(''),
      'twitter': new FormControl('', [Validators.pattern('(https?://)?([\\da-z.-]+)\\.([a-z.]{2,6})[/\\w .-]*/?')]),
      'website': new FormControl('', [Validators.pattern('(https?://)?([\\da-z.-]+)\\.([a-z.]{2,6})[/\\w .-]*/?')]),
      'instagram': new FormControl('', [Validators.pattern('(https?://)?([\\da-z.-]+)\\.([a-z.]{2,6})[/\\w .-]*/?')]),
      'facebook': new FormControl('', [Validators.pattern('(https?://)?([\\da-z.-]+)\\.([a-z.]{2,6})[/\\w .-]*/?')]),
    })
  }

  myProfile() {
    var url = 'admin/adminProfile';
    this.service.showSpinner();
    this.service.getApi(url, 1).subscribe(res => {
      // this.service.hideSpinner();  
      if (res['responseCode'] == 200) {
        this.userDetail = res['result']['contactUs'];
        this.editImage = this.userDetail.imageUrl;
        this.editForm.patchValue({
          'mobileNumber': this.userDetail.mobileNumber,
          'twitter': this.userDetail.twitter,
          'facebook': this.userDetail.website,
          'website': this.userDetail.instagram,
          'instagram': this.userDetail.facebook,
        })
        this.service.hideSpinner();
      } else {
        this.service.hideSpinner();
        this.service.toasterErr(res['responseMessage']);
      }
    }, err => {
      if (err['status'] == '401') {
        this.service.onLogout();
        this.service.toasterErr('Unauthorized Access');
      } else {
        this.service.toasterErr('Something Went Wrong');
      }
      // this.service.hideSpinner();
    })
  }

  // Image Functionality Start Here
  uploadImg($event): void {
    var img = $event.target.files[0];
    this.uploadImageFunc(img);
  }

  async uploadImg1($event) {
    this.imageUrl = await this.service.getBase64($event.target.files[0]);
  }

  uploadImageFunc(img) {
    var fb = new FormData();
    fb.append('file', img)
    this.service.showSpinner();
    this.service.postApi('account/upload-file', fb).subscribe(res => {
      // this.service.hideSpinner();
      if (res['status'] == '200') {
        this.editImage = res['data'];
        this.service.hideSpinner();
      }
    }, err => {
      this.service.hideSpinner();
      if (err['status'] == '401') {
        this.service.onLogout();
        this.service.toasterErr('Unauthorized Access');
      } else {
        this.service.toasterErr('Something Went Wrong');
      }
    })
  }


  updateProfile() {
    // if (this.editForm.invalid) {
    //   return
    // }
    var apiReq = {
      'mobileNumber': this.editForm.value.mobileNumber,
      'twitter': this.editForm.value.twitter,
      'facebook': this.editForm.value.website,
      'website': this.editForm.value.instagram,
      'instagram': this.editForm.value.facebook,
    }
    this.service.showSpinner();
    this.service.putApi('admin/updateContactUs', apiReq, 1).subscribe(res => {
      this.service.hideSpinner();
      if (res['responseCode'] == 200) {
        this.service.toasterSucc('Profile Updated Successfully');
        this.router.navigate(['/my-profile'])
      } else {
        this.service.hideSpinner();
        this.service.toasterErr(res['responseMessage']);
      }
    }, err => {
      this.service.hideSpinner();
      if (err['responseCode'] == '401') {
        this.service.toasterErr('Unauthorized Access');
      } else {
        this.service.toasterErr('Something Went Wrong');
      }
    })
  }

  preventSpace(event) {
    if (event.charCode == 32 && !event.target.value) {
      event.preventDefault()
    }
  }

}
