import { Component, OnInit } from "@angular/core";
import { ServiceService } from "../service.service";
import { ActivatedRoute, Router } from "@angular/router";
import { ThrowStmt } from "@angular/compiler";
declare var $: any;
@Component({
  selector: "app-kyc-management",
  templateUrl: "./kyc-management.component.html",
  styleUrls: ["./kyc-management.component.css"],
})
export class KycManagementComponent implements OnInit {
  searchText: string = "";
  searchText1: string = "";
  pageNumber: number = 1;
  companyPageNumber: number = 1;
  status: string;
  kycList: any = [];
  kycData: any = {};
  userKycId: any;
  kycDetail: any;
  itemsPerPage: any = 10;
  flip: boolean = false;
  countryList: any = [];
  country: any = "";
  tab: any = "user"
  minAge: Date;
  startdate: any
  enddate: any
  constructor(public service: ServiceService, public router: Router) { }

  ngOnInit() {
    this.ekFunctionZindgiKa();
    this.getCountryList();
    var today = new Date();
    var minAge = 0;
    this.minAge = new Date(today.getFullYear() - minAge, today.getMonth(), today.getDate())
  }

  fromDateVali: any = ''
  fromdate() {
    this.fromDateVali = new Date(this.startdate)
    this.fromDateVali = this.fromDateVali.getTime()
    console.log(this.fromDateVali);

  }

  // User KYC Filter List

  ekFunctionZindgiKa() {
    let fDate = this.startdate;
    let tDate = this.enddate;
    let data = {
      page: this.pageNumber,
      limit: this.itemsPerPage,
      fromDate: fDate,
      toDate: tDate,
      userType: this.filter,
      search: this.searchText,
      kycStatus: this.status,
      country: this.country
    }
    // let url = `wallet/get-all-transaction-history?page=${this.currentPage - 1}&pageSize=${this.itemsPerPage}${this.transferForm.value.fromDate ? "&fromDate=" + fromDate : ""}${this.transferForm.value.toDate ? "&toDate=" + toDate : ""}${this.transferForm.value.coinNameSe ? "&coinName=" + coinNameSe : ""}${this.transferForm.value.amount ? "&amount=" + amount : ""}${this.transferForm.value.type ? "&txnType=" + type : ""}${this.transferForm.value.searchTextTxnHash ? "&txnHash=" + searchTextTxnHash : ""}${this.transferForm.value.status ? "&status=" + status : ""}`
    //  let zindgi  =  `wallet/get-all-transaction-history?amount=${this.transferForm.value.amount}&coinName=${this.transferForm.value.coinNameSe}&fromDate=${Math.round(new Date(this.transferForm.value.fromDate).getTime())}&page=${this.currentPage - 1}&pageSize=${this.itemsPerPage}&status=${this.transferForm.value.status}&toDate=${Math.round(new Date(this.transferForm.value.toDate).getTime())}&txnHash=${this.transferForm.value.searchTextTxnHash}&txnType=${this.transferForm.value.type}`
    let url = `admin/listkyc`
    this.service.showSpinner();
    this.service.postApiFunc(url, data, 1).subscribe(
      (res: any) => {
        if (res.responseCode == 200) {
          this.service.hideSpinner();
          this.kycList = res.result.docs;
          this.kycDetail = res["result"].total;
        } else if (res.responseCode == 404) {
          this.service.hideSpinner();
          this.kycList = []
        } else {
          this.service.hideSpinner();
          this.kycList = []
        }
        //  this.totalRecords = res.data.totalCount;
      },
      (err: any) => {
        this.kycList = []
        this.service.hideSpinner();
        // this.service.toasterErr(err.error.responseMessage);
      }
    );
  }

  reset() {
    this.searchText = ""
    this.country = ""
    this.status = ""
    this.startdate = ""
    this.enddate = ""
    this.ekFunctionZindgiKa()
  }

  userType: any = "USER"
  filter: any = "USER"
  select(status, filter) {
    this.tab = status
    this.filter = filter
    this.userType = this.tab.toUpperCase();
    if (this.tab == 'user') {
      this.ekFunctionZindgiKa();
    } else {
      this.ekFunctionZindgiKa()
    }
  }

  // get country list
  getCountryList() {
    this.service.getApi("country/country", 0).subscribe((res) => {
      if (res["responseCode"] == 200) {
        this.countryList = res["result"].countries;
      }
    });
  }
  gotoview(id, tab) {
    this.router.navigate(["/kyc-action/id"], { queryParams: { id: id, tab: tab }, });
  }
  // getStatus Function
  getStatus(event) {
    this.pageNumber = 1;
    this.status = event.target.value;
    console.log(this.status);

    if (this.status != "") {
      this.ekFunctionZindgiKa();
    } else {
      this.ekFunctionZindgiKa();
    }
  }

  // companyFilterStatus

  getStatus1(event) {
    this.pageNumber = 1;
    this.status = event.target.value;
    if (this.status != "") {
      this.ekFunctionZindgiKa();
    } else {
      this.ekFunctionZindgiKa();
    }
  }

  userDetail: any
  userDetail1: any

  // Change Page Number
  changePageNumber(page) {
    this.pageNumber = page;
    this.ekFunctionZindgiKa();
  }
  changePageNumber1(page) {
    this.pageNumber = page;
    this.ekFunctionZindgiKa();
  }


  documentActionFunc(action, documentNumber, documentId) {
    var apiReq = {
      documentId: documentId,
      kycId: this.userKycId,
      reason: action == "ACCEPT" ? "Valid Document" : "Invalid Document",
      status: action,
    };
    this.service.showSpinner();
    this.service
      .post("account/admin/kyc-management/doc-status", apiReq)
      .subscribe(
        (res) => {
          this.service.hideSpinner();
        },
        (err) => {
          this.service.hideSpinner();
        }
      );
  }
  isAssending: boolean = true;
  sortAgent(key) {
    if (this.isAssending) {
      this.kycList = this.kycList.sort((a, b) => {
        return a[key] > b[key] ? 1 : -1;
      });
    } else {
      this.kycList = this.kycList.sort((a, b) => {
        return a[key] < b[key] ? 1 : -1;
      });
    }
  }

  //export User
  exportAsXLSX() {
    let dataArr = [];
    this.kycList.forEach((element, ind) => {

      dataArr.push({
        "S no": ind + 1,
        "Name": element.userDetails.firstName ? element.userDetails.firstName + element.userDetails.lastName : '',
        "Email": element.userDetails.email ? element.userDetails.email : '',

        "Mobile Number": element.userDetails.mobileNumber ? element.userDetails.mobileNumber : 'N/A',
        "Country": element.userDetails.country ? element.userDetails.country : 'N/A',
        "Status": element.approveStatus ? element.approveStatus : 'N/A',
        "Date & Time  ": element.createdAt ? element.createdAt.slice(0, 10) : 'N/A',
      })
      // let fhkds= JSON.stringify(dataArr)
    })

    this.service.exportAsExcelFile(dataArr, 'User Kyc list');
  }

  //export Company
  exportAsXLSX1() {
    let dataArr = [];
    this.kycList.forEach((element, ind) => {

      dataArr.push({
        "S no": ind + 1,
        "Name": element.userDetails.firstName ? element.userDetails.firstName + ' ' + element.userDetails.lastName : '',
        "Email": element.userDetails.email ? element.userDetails.email : '',
        "Mobile Number": element.userDetails.mobileNumber ? element.userDetails.mobileNumber : 'N/A',
        "Company Name": element.userDetails.companyName ? element.userDetails.companyName : 'N/A',
        "GST Number": element.userDetails.gstNumber ? element.userDetails.gstNumber : 'N/A',
        "TIN Number": element.userDetails.tinNumber ? element.userDetails.tinNumber : 'N/A',
        "Country": element.userDetails.country ? element.userDetails.country : 'N/A',
        "Status": element.approveStatus ? element.approveStatus : 'N/A',
        "Registration Date & Time ": element.createdAt ? element.createdAt.slice(0, 10) : 'N/A',
      })
      // let fhkds= JSON.stringify(dataArr)
    })

    this.service.exportAsExcelFile(dataArr, 'Company Kyc list');
  }

}
