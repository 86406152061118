import { HttpClient } from '@angular/common/http';
import { Component, OnInit } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { ServiceService } from 'src/app/service.service';

@Component({
  selector: 'app-edit-tokenomics-currency',
  templateUrl: './edit-tokenomics-currency.component.html',
  styleUrls: ['./edit-tokenomics-currency.component.css']
})
export class EditTokenomicsCurrencyComponent implements OnInit {

  addSwatchesForm: FormGroup
  addSwatchesForm1: FormGroup
  imageUrl
  coinName: any
  itemPerPage = 10;
  currentPage = 1;
  totalItems: any;
  _id: any
  colorListArray: any = []
  showImageField: any = [];
  constructor(private router: Router, public commonService: ServiceService, private httpClient: HttpClient, public active: ActivatedRoute) {
    this.active.queryParams.subscribe((res: any) => {
      this._id = res.id;
    })
  }

  ngOnInit() {
    this.addSwatchesFormValidation1();
    this.getCoinDetailsData()
  }

  addSwatchesFormValidation1() {
    this.addSwatchesForm1 = new FormGroup({
      'coinName': new FormControl('', [Validators.required]),
    })
  }






  addSwatches() {
    let url = `admin/tokonomicsCurrencies?_id=${this._id}`
    let data = {
      "name": this.addSwatchesForm1.value.coinName
    }
    console.log(data);
    this.commonService.showSpinner();
    this.commonService.putApi(url, data, 1).subscribe((res: any) => {
      console.log("add category response ==>", res)
      if (res.responseCode == 200) {
        this.commonService.hideSpinner();
        this.commonService.toasterSucc(res.responseMessage
        );
        this.router.navigate(['/list-tokenomics'])
      } else {
        this.commonService.hideSpinner();
        this.commonService.toasterErr(res.responseMessage
        )
      }
    })
  }

  dataCoinFetch: any
  coinIdData: any
  getCoinDetailsData() {
    var url = `admin/tokonomicsCurrencies?_id=${this._id}`
    this.commonService.showSpinner();
    this.commonService.getApi(url, 1).subscribe(res => {

      this.commonService.hideSpinner();
      if (res['responseCode'] == 200) {
        this.commonService.hideSpinner();
        this.dataCoinFetch = res['result'][0]
        console.log(this.dataCoinFetch);
        console.log(this.dataCoinFetch.name);

        this.addSwatchesForm1.patchValue({
          coinName: this.dataCoinFetch.name,
        })
      }
    }, err => {

      this.commonService.hideSpinner();
      this.commonService.toasterErr(err.error.responseMessage);
    })
  }



  // Image Functionality Start Here
  uploadImg($event): void {
    var img = $event.target.files[0];
    this.uploadImageFunc(img);
  }
  uploadImageFunc(img) {
    var fb = new FormData();
    fb.append('file', img)
    this.commonService.showSpinner();
    this.commonService.postApi('account/upload-file', fb).subscribe(res => {
      // this.commonService.hideSpinner();
      if (res['status'] == '200') {
        this.imageUrl = res['data'];
        this.commonService.hideSpinner();
      }
    }, err => {
      this.commonService.hideSpinner();
      if (err['status'] == '401') {
        this.commonService.onLogout();
        this.commonService.toasterErr('Unauthorized Access');
      } else {
        this.commonService.toasterErr('Something Went Wrong');
      }
    })
  }

}
