import { HttpClient } from '@angular/common/http';
import { Component, OnInit } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { Router, ActivatedRoute, Event } from '@angular/router';
import { ServiceService } from 'src/app/service.service';

@Component({
  selector: 'app-staking-edit',
  templateUrl: './staking-edit.component.html',
  styleUrls: ['./staking-edit.component.css']
})
export class StakingEditComponent implements OnInit {

  addSwatchesForm: FormGroup
  imageUrl

  itemPerPage = 10;
  currentPage = 1;
  totalItems: any;
  colorListArray: any = []
  showImageField: any = [];
  coinAdd: any
  id: any
  details: any

  constructor(private router: Router, public commonService: ServiceService, private httpClient: HttpClient, private activatedRoute: ActivatedRoute) {
    this.activatedRoute.queryParams.subscribe(res => {
      this.coinAdd = res.coinName,
        this.id = res.id
    })
  }

  ngOnInit() {
    this.coinName();
    this.addSwatchesFormValidation();
    this.viewStaking();
  }
  addSwatchesFormValidation() {
    this.addSwatchesForm = new FormGroup({
      // 'colorName': new FormControl('', [Validators.required]),
      // 'contractAddress': new FormControl('', [Validators.required, Validators.pattern(/^0x[a-fA-F0-9]{40}$/)]),
      'assets': new FormControl('', [Validators.required]),
      'duration': new FormControl('', [Validators.required]),
      'minAmount': new FormControl('', [Validators.required]),
      'maxAmount': new FormControl('', [Validators.required]),
      'intrestRate': new FormControl('', [Validators.required]),
      'terms': new FormControl('', [Validators.required])

    })
  }

  viewStaking() {
    let url = `admin/stakeInterestView?interestId=${this.id}`
    this.commonService.showSpinner();
    this.commonService.getApi(url, 0).subscribe((res) => {
      if (res['responseCode'] == 200) {
        this.commonService.hideSpinner();
        this.commonService.toasterSucc(res['responseMessage']);
        this.details = res['result']
        console.log(this.details);

        console.log(this.details);
        this.addSwatchesForm.patchValue({
          assets: this.details.coinId.coinName,
          minAmount: this.details.min,
          maxAmount: this.details.max,
          intrestRate: this.details.interest,
          termsConditions: this.details.terms
        })
      } else {
        this.commonService.hideSpinner();
        this.commonService.toasterErr(res['responseMessage']);
      }
    }, (err) => {
      this.commonService.hideSpinner();
      this.commonService.toasterErr(err.error.responseMessage);
    })
  }

  cId: any
  coinId(event) {
    this.cId = event.target.value
    console.log(this.cId);

  }
  totalDuration: any
  duration(event) {
    this.totalDuration = event.target.value
    console.log(this.totalDuration);
  }

  updateStaking() {
    let data = {
      interestId: this.id,
      coinId: this.cId,
      interest: this.addSwatchesForm.value.intrestRate + "",
      min: this.addSwatchesForm.value.minAmount + "",
      max: this.addSwatchesForm.value.maxAmount + "",
      termsConditions: this.addSwatchesForm.value.terms
    }
    let url = `admin/editStakeInterest`
    this.commonService.showSpinner();
    this.commonService.putApi(url, data, 0).subscribe((res) => {
      if (res['responseCode'] == 200) {
        this.commonService.hideSpinner();
        this.commonService.toasterSucc(res['responseMessage']);
        this.router.navigate(['/staking-list'])
      } else {
        this.commonService.hideSpinner();
        this.commonService.toasterErr(res['responseMessage'])
      }
    }, (err) => {
      this.commonService.hideSpinner();
      this.commonService.toasterErr(err.error.message)
    })
  }





  addSwatches() {

    // this.router.navigate(['/swatches/list-swatches'])
    let url = `wallet/coin/admin/add-Coin?network=${this.addSwatchesForm.value.network}`
    let data = {
      'contractAddress': this.addSwatchesForm.value.contractAddress,
      'coinFullName': this.addSwatchesForm.value.coinFullName,
      'coinShortName': String(this.addSwatchesForm.value.coinShortName).toUpperCase(),
      'coinType': this.addSwatchesForm.value.coinType,
      'coinImage': this.imageUrl
    }
    this.commonService.showSpinner();
    this.commonService.post(url, data).subscribe((res: any) => {
      console.log("add category response ==>", res)
      if (res.status == 200) {
        this.storageUpdate(String(this.addSwatchesForm.value.coinShortName).toUpperCase())
        this.commonService.toasterSucc(res.message);
        this.router.navigate(['/fee-management'])
      } else {
        this.commonService.hideSpinner();
        this.commonService.toasterErr(res.message)
      }
    })
  }





  // Image Functionality Start Here
  uploadImg($event): void {
    var img = $event.target.files[0];
    this.uploadImageFunc(img);
  }
  uploadImageFunc(img) {
    var fb = new FormData();
    fb.append('file', img)
    this.commonService.showSpinner();
    this.commonService.postApi('account/upload-file', fb).subscribe(res => {
      // this.commonService.hideSpinner();
      if (res['status'] == '200') {
        this.imageUrl = res['data'];
        this.commonService.hideSpinner();
      }
    }, err => {
      this.commonService.hideSpinner();
      if (err['status'] == '401') {
        this.commonService.onLogout();
        this.commonService.toasterErr('Unauthorized Access');
      } else {
        this.commonService.toasterErr('Something Went Wrong');
      }
    })
  }

  dataArray: any
  checkWalletAddress($evant) {
    let url = `https://api.pancakeswap.info/api/v2/tokens/${this.addSwatchesForm.value.contractAddress}`
    this.httpClient.get(url).subscribe(res => {
      this.dataArray = res['data']
      this.addSwatchesForm.patchValue({
        coinShortName: this.dataArray.symbol,
        coinFullName: this.dataArray.name
      })
    })
  }


  storageUpdate(coin) {
    let data = {
      "coinName": coin,
      "storageType": 'HOT'
    }
    this.commonService.showSpinner();
    this.commonService.post('wallet/admin/hot-cold-storage/create-storage-wallet', data).subscribe((res) => {
      this.commonService.hideSpinner();
      if (res['status'] == 200) {
        this.commonService.toasterSucc(res['message'])
        this.newAddressGenerate(coin)
      }
      else {
        this.commonService.toasterErr(res['message']);
        this.commonService.hideSpinner();
      }
    }, (err) => {
      this.commonService.hideSpinner();
    })
  }

  coinList: any
  coinName() {

    this.commonService.showSpinner();
    this.commonService.get('admin/coinList').subscribe((res) => {
      this.commonService.hideSpinner();
      if (res['responseCode'] == 200) {
        this.coinList = res['result']

        this.commonService.toasterSucc(res['responseMessage'])
      }
      else {
        this.commonService.toasterErr(res['responseMessage']);
        this.commonService.hideSpinner();
      }
    }, (err) => {
      this.commonService.hideSpinner();
    })
  }

  newAddressGenerate(coin) {
    let data = {
      "accName": 1,
      "coinName": coin,
      "storageType": 'HOT'
    }
    this.commonService.showSpinner();
    this.commonService.post('wallet/admin/hot-cold-storage/get-new-storage-address', data).subscribe((res) => {
      this.commonService.hideSpinner();
      if (res['status'] == 200) {
        this.commonService.toasterSucc(res['message'])
      }
      else {
        this.commonService.toasterErr(res['message']);
        this.commonService.hideSpinner();
      }
    }, (err) => {
      this.commonService.hideSpinner();
    })
  }

}
