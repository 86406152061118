import { ServiceService } from './service.service';
import { Injectable } from '@angular/core';
import {
    HttpRequest,
    HttpHandler,
    HttpEvent,
    HttpInterceptor,
    HttpErrorResponse
} from '@angular/common/http';
import { Observable, throwError } from 'rxjs';
import { catchError } from 'rxjs/operators';

@Injectable()
export class MockHttpCalIInterceptor implements HttpInterceptor {

    constructor(public commonService: ServiceService) { }

    intercept(request: HttpRequest<unknown>, next: HttpHandler): Observable<HttpEvent<unknown>> {
        console.log('Interceptor Working');

        return next.handle(request)
            .pipe(
                catchError((error: HttpErrorResponse) => {
                    let errorMsg = 'something went wrong';

                    this.commonService.hideSpinner();
                    if (error.error instanceof ErrorEvent) {
                        errorMsg = `Error: ${error["responseMessage"]}`;
                    }
                    else {
                        console.log("interceptor error->", error["error"]["responseCode"]);
                        switch (error["error"]["responseCode"]) {
                            case 400:
                                this.commonService.toasterErr(error["responseMessage"]);
                                break;
                            case 440:
                                this.commonService.toasterErr('Your session has expired please login again.');
                                this.commonService.onLogout();
                                break;
                            case 403:
                                this.commonService.toasterErr('Unauthorized Access.');
                                break;
                            case 500:
                                this.commonService.toasterErr('Internal Server Error.');
                                // this.commonService.onLogout();
                                break;
                            case 404:
                                break;
                            default:
                            // this.commonService.toasterErr(error.error["responseMessage"] || 'Something went wrong');
                            // this.commonService.onLogout()
                        }
                    }
                    return throwError(error);
                })
            )
    }
}
