import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { ServiceService } from 'src/app/service.service';

@Component({
  selector: 'app-feedback-view',
  templateUrl: './feedback-view.component.html',
  styleUrls: ['./feedback-view.component.css']
})
export class FeedbackViewComponent implements OnInit {

  fixedDepositId: any
  editData: any
  tab: any
  id: any
  fixedStack: any;
  constructor(private router: Router, private activatedroute: ActivatedRoute, public commonService: ServiceService) { }

  ngOnInit() {
    this.activatedroute.queryParams.subscribe((res) => {
      this.id = res.id
      this.fixedStack = res.fixedStack
    })
    this.fdView()
  }
  back() {
    this.router.navigate(['/feedback-list'])
  }


  fdView() {

    // this.commonService.showSpinner()
    this.commonService.get("contactUs/viewFeedback?feedbackId=" + this.id).subscribe((res: any) => {
      if (res.responseCode == 200) {
        this.editData = res.result;



        // this.commonService.toasterSucc(res.responseMessage)
        this.commonService.hideSpinner()

      }
    }, err => {

      this.commonService.hideSpinner();
      if (err['responseCode'] == '401') {
        //  this.commonService.onLogout();
        this.commonService.toasterErr('Unauthorized Access');
      } else {
        this.commonService.toasterErr('Something Went Wrong');
      }
    })

  }
  toFixed(number, toLength: number) {
    return Number(number).toFixed(toLength)
  }
}
