import { Component, OnInit } from '@angular/core';
import { FormGroup, FormControl, Validators } from '@angular/forms';
import { ServiceService } from '../service.service';
import { Router } from '@angular/router';

@Component({
  selector: 'app-change-password',
  templateUrl: './change-password.component.html',
  styleUrls: ['./change-password.component.css']
})
export class ChangePasswordComponent implements OnInit {
  resetPasswordForm: FormGroup;
  // token: string;
  passType: boolean = true;
  passType1: boolean = true;
  passType2: boolean = true;

  constructor(public service: ServiceService, public route: Router) { }

  ngOnInit() {
    // let url = window.location.href.split('/')
    // this.token = window.location.href.split('=')[1];
    this.resetPasswordForm = new FormGroup({
      oldPassword: new FormControl('', Validators.required),
      password: new FormControl('', [Validators.required, Validators.pattern(/^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[@$!%*?&])[A-Za-z\d@$!%*?&]{8,}$/)]),
      confirmPassword: new FormControl('', Validators.required)
    })
  }

  // reset password
  resetPasswordFunc() {
    var apireq = {
      'newPassword': this.resetPasswordForm.value.confirmPassword,
      'oldPassword': this.resetPasswordForm.value.oldPassword
    }
    this.service.showSpinner();
    this.service.patchApi('admin/changePassword', apireq, 1).subscribe(res => {
      this.service.hideSpinner();
      if (res['responseCode'] == 200) {
        this.service.toasterSucc('Password updated successfully.');
        this.route.navigate(['/login'])
      } else {
        this.service.toasterErr(res['responseMessage']);
      }
    }, err => {
      this.service.hideSpinner();
      this.service.toasterErr(err.error.responseMessage);
    })
  }

}
