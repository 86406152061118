import { Component, OnInit } from '@angular/core';
import { FormControl, FormGroup } from '@angular/forms';
import { Router } from '@angular/router';
import { ServiceService } from 'src/app/service.service';


declare var $: any
@Component({
  selector: 'app-list-aip-plan',
  templateUrl: './list-aip-plan.component.html',
  styleUrls: ['./list-aip-plan.component.css']
})
export class ListAipPlanComponent implements OnInit {
  aipList: any = []
  searchForm: FormGroup;
  bodyListArray: any = [];
  itemPerPage = 10;
  currentPage = 1;
  totalItems: any;
  bannerId: any;
  careerStatus: string;

  contentId: any;

  constructor(public commonService: ServiceService, private router: Router) { }

  ngOnInit() {
    this.aipPlan()
  }

  aipPlan() {
    this.commonService.showSpinner();
    this.commonService.get("wallet/view-auto-invest").subscribe(
      (res: any) => {
        if (res.status == 200) {
          this.aipList = res.data;
          this.totalItems = res.data.length
          this.commonService.hideSpinner();
        } else {
          this.commonService.hideSpinner();
        }
      },
      (error) => {
        this.commonService.hideSpinner();
      }
    );
  }

  openModal(careerStatus, contentId) {
    this.contentId = contentId;
    this.careerStatus = careerStatus;
    // if (action == 'DELETE') {
    //   $('#deleteModal').modal('show')

    // } 
    if (careerStatus == 'BLOCK') {
      $('#block').modal('show')
    }
    else {
      $('#active').modal('show')
    }
  }

  // Search by Date
  //    searchByDate(){

  //  }

  searchFormValidation() {
    this.searchForm = new FormGroup({
      search: new FormControl(''),
      status: new FormControl(''),
      fromDate: new FormControl(''),
      toDate: new FormControl('')
    });
  }
  searchFormSubmit() {

    if (this.searchForm.value.search || this.searchForm.value.status || this.searchForm.value.fromDate || this.searchForm.value.toDate) {
      //   if(this.searchForm.value.fromDate > this.searchForm.value.toDate){
      //     this.commonService.errorToast('To date should be greater than From date')
      //     // return
      //     // this.searchForm.value.fromDate = '';
      //     // this.messege = "please enter right date"
      //   }
      //   else if(this.searchForm.value.fromDate <= this.searchForm.value.toDate){

      //  this.oldDate = new Date(this.searchForm.value.fromDate).toISOString()
      //  this.newDate = new Date(this.searchForm.value.toDate+'T23:59:59.999Z')
      //  console.log('this.oldDate',this.oldDate );
      //  console.log('this.newDate',this.newDate );
      //       }
      // this.fromDate =this.searchForm.value.fromDate
    }
  }
  searchFormReset() {
    if (this.searchForm.value.search || this.searchForm.value.status || this.searchForm.value.fromDate || this.searchForm.value.toDate) {
      this.searchForm.reset({
        search: '',
        status: '',
        fromDate: '',
        toDate: ''
      });
   
    }
  }

  selectStatus() {
    this.currentPage = 1
  }
  // -------------------- get category list --------------------- //

  pagination(event) {
    console.log(event)
    this.currentPage = event;
    
  }

  addBody(planId) {
    this.router.navigate(['/add-aip-percent'], { queryParams: { planId: planId } })
  }
  // view category
  viewBody(contentId) {
    // alert(contentId)
    this.router.navigate(['/view-aip-details'], { queryParams: { planId: contentId } })
  }

  // edit category
  editBody(contentId) {
    // alert(contentId)

    this.router.navigate(['/edit-blog'], { queryParams: { planId: contentId } })
  }
  // editCategory(contentId) {
  //   this.router.navigate(['/embellishment/edit-embellishment'])
  // }
  // blockUnblockUserModal(){

  // }
  // ------------------------------- delete hospital ----------------------------- //
  deleteCategoryModal(contentId) {
    $('#deleteCategory').modal('show')
    this.contentId = contentId
  }
  deleteCategory() {
    // let data = {
    //   hospitalId: this.hospitalId
    // }
    // console.log(data)
    let apiReqUrl: any = "wallet/delete-auto-plan-by-planId?planId=" + this.contentId
    this.commonService.showSpinner();
    this.commonService.delete(apiReqUrl).subscribe((res: any) => {
      // console.log("delete category user response ==>", res)
      $('#deleteCategory').modal('hide');
      if (res.status == 200) {
        this.aipPlan()
        this.commonService.toasterSucc(res.message);
      } else {
        this.commonService.hideSpinner();
        this.commonService.toasterErr(res.message)
      }
    })
  }
}
