import { Component, OnInit } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { IMyDateModel, IMyDpOptions } from 'mydatepicker';
import { ServiceService } from 'src/app/service.service';
declare var $: any
@Component({
  selector: 'app-list-buy-sell-tokens',
  templateUrl: './list-buy-sell-tokens.component.html',
  styleUrls: ['./list-buy-sell-tokens.component.css']
})
export class ListBuySellTokensComponent implements OnInit {

  pageNumber: number = 1;
  totalRecord: any;
  pageSize: number = 10
  totalItems: any;
  fromDate: any = ''
  beDisable: boolean = true;
  userForm: FormGroup;
  userID: any;
  IsToDate: boolean = true;
  calender: any = { todate: '', formdate: '' }
  action: any;
  userList: any = [];
  searchText: string = ''
  userDetail: any = {};
  space: string = ' ';
  convertFormat: any;
  newArray: any = [];
  newFromDate: number;
  newToDate: any;
  countryList: any = []
  minAge: Date;
  tab: any = "user"
  tab1: any = ''
  constructor(private router: Router, public service: ServiceService) {

  }

  ngOnInit() {
    this.userForm = new FormGroup({
      'startdate': new FormControl('', Validators.required),
      'enddate': new FormControl('', Validators.required),
      'searchText': new FormControl(''),
      'sedan': new FormControl(''),
      'hatch': new FormControl(''),
      'suv': new FormControl(''),
      'country': new FormControl(''),
      'status': new FormControl('')
    })
    this.onDateChanged
    // this.getUserCompanyList()
    this.ekFunctionZindgiKa();
    this.getCountryList()
    var today = new Date();
    var minAge = 0;
    this.minAge = new Date(today.getFullYear() - minAge, today.getMonth(), today.getDate())

  }
  fromDateVali: any = ''
  fromdate() {
    this.fromDateVali = new Date(this.userForm.value.startdate)
    this.fromDateVali = this.fromDateVali.getTime()
    console.log(this.fromDateVali);

  }
  userType1: any = "COMPANY"
  ekFunctionZindgiKa() {
    let startdate = this.userForm.value.startdate;
    let enddate = this.userForm.value.enddate;
    let searchText = this.userForm.value.searchText;
    let country = this.userForm.value.country;
    let status1 = this.userForm.value.status;
    this.userType1 = this.uesrType
    // let url = `wallet/get-all-transaction-history?page=${this.currentPage - 1}&pageSize=${this.itemsPerPage}${this.transferForm.value.fromDate ? "&fromDate=" + fromDate : ""}${this.transferForm.value.toDate ? "&toDate=" + toDate : ""}${this.transferForm.value.coinNameSe ? "&coinName=" + coinNameSe : ""}${this.transferForm.value.amount ? "&amount=" + amount : ""}${this.transferForm.value.type ? "&txnType=" + type : ""}${this.transferForm.value.searchTextTxnHash ? "&txnHash=" + searchTextTxnHash : ""}${this.transferForm.value.status ? "&status=" + status : ""}`
    //  let zindgi  =  `wallet/get-all-transaction-history?amount=${this.transferForm.value.amount}&coinName=${this.transferForm.value.coinNameSe}&fromDate=${Math.round(new Date(this.transferForm.value.fromDate).getTime())}&page=${this.currentPage - 1}&pageSize=${this.itemsPerPage}&status=${this.transferForm.value.status}&toDate=${Math.round(new Date(this.transferForm.value.toDate).getTime())}&txnHash=${this.transferForm.value.searchTextTxnHash}&txnType=${this.transferForm.value.type}`
    let url = `admin/listUser?page=${this.pageNumber}&limit=${this.pageSize}${this.userForm.value.status ? "&status1=" + status1 : ""}${this.userForm.value.searchText ? "&search=" + searchText : ""}${this.userForm.value.startdate ? '&fromDate=' + new Date(startdate).toISOString() : ''}${this.userForm.value.enddate ? '&toDate=' + new Date(enddate).toISOString() : ''}${this.userType1 ? '&userType1=' + this.userType1 : ""}${this.userForm.value.country ? '&country=' + country : ""}`
    // this.service.showSpinner();
    this.service.getApi(url, 1).subscribe(
      (res: any) => {
        if (res.responseCode == 200) {
          this.userList = res.result.docs;
          this.totalItems = res.result.total;
          console.log(this.userList._id);

          // this.service.toasterSucc(res.responseMessage)
        } else {

          this.service.hideSpinner();
          this.service.toasterErr(res.responseMessage)
        }
      },
      (err: any) => {
        this.userList = []
        this.service.hideSpinner();
        this.service.toasterErr(err.error.responseMessage);
      }
    );
  }

  disableSearchbtn() {

    if (this.userForm.value.startdate || this.userForm.value.enddate || this.userForm.value.country || this.userForm.value.status || this.userForm.value.searchText) {
      return false
    }
    else {
      return true
    }

  }


  uesrType: any = "USER"
  select(status, status1) {
    this.tab = status;
    this.tab1 = status1
    this.uesrType = this.tab.toUpperCase()
    if (this.tab == 'company') {
      this.ekFunctionZindgiKa();
    } else {
      this.ekFunctionZindgiKa()
    }
  }

  reset() {
    if (
      this.userForm.value.searchText ||
      this.userForm.value.startdate ||
      this.userForm.value.enddate ||
      this.userForm.value.sedan ||
      this.userForm.value.hatch ||
      this.userForm.value.suv ||
      this.userForm.value.country ||
      this.userForm.value.status


    ) {
      this.userForm.reset({
        startdate: "",
        enddate: "",
        searchText: "",
        sedan: '',
        hatch: '',
        suv: '',
        country: '',
        status: ''
      });
      this.ekFunctionZindgiKa();
    }
  }

  pagination(page) {
    this.pageNumber = page;
    console.log(page);

    // this.getUserList()
    this.ekFunctionZindgiKa()
    // this.getUserCompanyList()


  }
  // get country list
  getCountryList() {
    this.service.getApi('country/country', 0).subscribe((res) => {
      if (res['responseCode'] == 200) {
        this.countryList = res['result']['countries']
      }
    })
  }
  /**************** Date managing***************/
  public myDatePickerOptions: IMyDpOptions = {
    dateFormat: 'yyyy-mm-dd',
    editableDateField: false,
    openSelectorOnInputClick: false,
    disableSince: { year: 0, month: 0, day: 0 }
  };
  public toDate: IMyDpOptions = {
    dateFormat: 'yyyy-mm-dd',
    editableDateField: false,
    openSelectorOnInputClick: false,
    disableUntil: { year: 0, month: 0, day: 0 }
  };

  onDateChanged() {
    let d = new Date();
    let copy1 = this.getCopyOfOptions();
    copy1.disableSince = {
      year: d.getFullYear(),
      month: d.getMonth() + 1,
      day: d.getDate()
    };
    this.myDatePickerOptions = copy1;
  }
  //Returns copy of myDatePickerOptions
  getCopyOfOptions(): IMyDpOptions {
    return JSON.parse(JSON.stringify(this.myDatePickerOptions));
  }


  public onChange(event: IMyDateModel) {
    if (event.formatted) {
      this.beDisable = false
      let d: Date = new Date(event.jsdate.getTime());
      d.setDate(d.getDate() - 1);
      let copy: IMyDpOptions = this.getCopyOfToDateOpt();
      copy.disableUntil = {
        year: d.getFullYear(),
        month: d.getMonth() + 1,
        day: d.getDate()
      };
      this.toDate = copy;
    }

  }
  getCopyOfToDateOpt(): IMyDpOptions {
    return JSON.parse(JSON.stringify(this.toDate));
  }
  /*******************Date managing Ends Here**************/


  // Get List of User
  getUserList() {
    var startdate = this.userForm.value.startdate.epoc ? (Number(this.userForm.value.startdate.epoc) * 1000) : ''
    var enddate = this.userForm.value.enddate.epoc ? (Number(this.userForm.value.enddate.epoc) * 1000) : ''
    // var url = "account/admin/user-management/filter-user-details?&page=1";
    var url = `${"admin/listUser?status=" + (this.userForm.value.status) + "&search=" + this.userForm.value.searchText + "&fromDate=" + startdate + "&toDate=" + enddate + "&page=" + (this.pageNumber - 1) + "&limit=10"}`
    this.service.showSpinner();
    this.service.getApi(url, 1).subscribe((res: any) => {
      this.service.hideSpinner();
      if (res['responseCode'] == 200) {
        this.userList = res.result.docs;
        this.totalItems = res.result.total;
        console.log(this.totalItems);

      }
    }, err => {
      this.userList = []
      this.service.hideSpinner();
      if (err['responseCode'] == '401') {
        this.service.onLogout();
        this.service.toasterErr('Unauthorized Access');
      } else {
        this.service.hideSpinner()
        this.service.toasterErr('Something Went Wrong');
      }
    })
  }


  // Delete / Block Function
  openModal(action, userId) {
    this.action = action;
    this.userID = userId;
    console.log(this.userID + "================" + this.action);

    if (action == 'DELETE') {
      $('#deleteModal').modal('show')

    } else if (action == 'BLOCK') {
      $('#block').modal('show')
    }
    else {
      $('#active').modal('show')
    }
  }
  //User Details
  userdetails(userId, email) {
    this.router.navigate(['/user-details/'], { queryParams: { userId, email } })
  }
  walletdetail(userId) {
    this.router.navigate(['walletdetails/' + userId])
  }

  //export User
  exportAsXLSX() {
    let dataArr = [];
    this.userList.forEach((element, ind) => {

      dataArr.push({
        "S no": ind + 1,
        "Name": element.firstName + '' + element.lastName ? element.firstName : '',
        "Email": element.email ? element.email : '',

        "Mobile Number": element.mobileNumber ? element.mobileNumber : 'N/A',
        "Country": element.country ? element.country : 'N/A',
        "Status": element.status ? element.status : 'N/A',
        "Registration Date & Time ": element.createdAt ? element.createdAt.slice(0, 10) : 'N/A',
      })
      // let fhkds= JSON.stringify(dataArr)
    })

    this.service.exportAsExcelFile(dataArr, 'User list');
  }

  // Delete User
  deleteFunction() {
    var url = `admin/deleteUser`;
    let data = {
      _id: this.userID
    }
    this.service.showSpinner();
    this.service.deleteApi(url, data, 1).subscribe(res => {

      this.service.hideSpinner();
      if (res['responseCode'] == 200) {
        $('#deleteModal').modal('hide')
        this.service.toasterSucc('User Deleted Successfully');
        this.ekFunctionZindgiKa();
      }
    }, err => {

      this.service.hideSpinner();
      if (err['responseCode'] == '401') {
        this.service.onLogout();
        this.service.toasterErr('Unauthorized Access');
      } else {
        this.service.toasterErr('Something Went Wrong');
      }
    })
  }

  performAction() {
    var url = `admin/blockUnblockUser`;
    let data = {
      _id: this.userID
    }
    this.service.showSpinner();
    this.service.putApi(url, data, 1).subscribe(res => {

      this.service.hideSpinner();
      if (res['responseCode'] == 200) {
        if (this.action == 'BLOCK') {
          $('#block').modal('hide');
          this.service.toasterSucc('User Blocked Successfully');
        }
        else {
          $('#active').modal('hide');
          this.service.toasterSucc('User Activated Successfully');
        }
        this.ekFunctionZindgiKa();
      }
    }, err => {

      this.service.hideSpinner();
      if (err['responseCode'] == '401') {
        this.service.onLogout();
        this.service.toasterErr('Unauthorized Access');
      } else {
        this.service.toasterErr('Something Went Wrong');
      }
    })
  }
  formdate() {
    this.fromDate = new Date(this.calender.formdate)
    this.fromDate = this.fromDate.getTime()
    this.IsToDate = false;

  }

  goAdmin() {
    this.router.navigate(['/admin-management'])
  }

  isAssending: boolean = true;

  sortAgent(key) {
    if (this.isAssending) {
      this.userList = this.userList.sort((a, b) => {
        return a[key] > b[key] ? 1 : -1;
      });
    } else {
      this.userList = this.userList.sort((a, b) => {
        return a[key] < b[key] ? 1 : -1;
      });
    }
  }

}
