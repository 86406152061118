import { Component, OnInit } from '@angular/core';
import { ServiceService } from '../service.service';
import { ActivatedRoute, Router } from '@angular/router';

@Component({
  selector: 'app-term-and-service',
  templateUrl: './term-and-service.component.html',
  styleUrls: ['./term-and-service.component.css']
})
export class TermAndServiceComponent implements OnInit {
  terms: any = [];
  id: any;
  dataa: any = [];
  constructor(public service: ServiceService, public route: ActivatedRoute, private router: Router) { }

  ngOnInit() {
    this.route.params.subscribe(x => {

      this.id = x['_id'];

    })
    this.getListCode();
  }


  // Get List Code
  getListCode() {
    this.service.showSpinner();
    this.service.getApi('static/static/TermsConditions', 0).subscribe(res => {

      this.dataa = res['result']
      this.service.hideSpinner();
      // if (res['responseCode'] == 200) {
      //   setTimeout(() => {
      //     this.dataa = res['result'][0];
      //   }, 500);
      //  }
      // this.terms = data.filter(x=>(x.staticContentId == this.id))
    }, err => {

      this.service.hideSpinner();
      if (err['responseCode'] == '401') {
        this.service.onLogout();
        this.service.toasterErr('Unauthorized Access');
      } else {
        this.service.toasterErr('Something Went Wrong');
      }
    })
  }

  // Save Abou Us Functionality
  saveTerms() {
    var apiReq = {
      "_id": this.id,
      "title": this.dataa.title,
      "description": this.dataa.description
    }
    //  var apiReq = {
    //   "pageKey": "Privacy Policy",
    //   "pageData": this.dataa.pageData
    // }
    // this.service.showSpinner();
    // this.service.post('static/update-static-content-data',apiReq).subscribe(res=>{
    this.service.showSpinner();
    this.service.putApi('static/static', apiReq, 0).subscribe(res => {

      this.service.hideSpinner();
      if (res['responseCode'] == 200) {
        this.getListCode();
        this.router.navigate[('/statics-content')]

        this.service.toasterSucc('Terms & Condition Updated Successfully')
      } else {
        this.service.toasterErr('Terms & Condition Not Updated ')
      }
    }, err => {

      this.service.hideSpinner();
      if (err['responseCode'] == '401') {
        this.service.onLogout();
        this.service.toasterErr('Unauthorized Access');
      } else {
        this.service.toasterErr('Something Went Wrong');
      }
    })
  }

}
