import { Component, OnInit } from '@angular/core';
import { FormGroup, FormControl } from '@angular/forms';
import { Router } from '@angular/router';
import { ServiceService } from '../service.service';
import { IMyDpOptions, IMyDateModel } from 'mydatepicker';
import { HttpClient } from '@angular/common/http';
import { Logs } from 'selenium-webdriver';
declare var $: any;

@Component({
  selector: 'app-bank-management',
  templateUrl: './bank-management.component.html',
  styleUrls: ['./bank-management.component.css']
})
export class BankManagementComponent implements OnInit {
  userID: any;
  action: any;
  staffList: any = [];
  pageNumber: number = 1;
  limit: number = 10;
  staffForm: FormGroup;
  staffLength: any;
  ipAddress: any;
  beDisable: boolean = true;
  bankDetailId: any;
  currTab: any = 'HOT'
  status: any

  constructor(public router: Router, public service: ServiceService, public http: HttpClient) {
    this.staffForm = new FormGroup({
      status: new FormControl(''),
      role: new FormControl(''),
      fromDate: new FormControl(''),
      toDate: new FormControl(''),
      search: new FormControl('')
    })
    // this.http.get<{ip:string}>('https://jsonip.com')
    // .subscribe( data => {
    //   this.ipAddress = data.ip
    // })
  }

  ngOnInit() {
    this.getStaffList();
  }
  bankStatus: any
  /** to switch between tabs */
  selectTab(tab, bankStatus) {
    this.bankStatus = bankStatus
    this.currTab = tab;
    if (tab == 'HOT') {
      // this.adminFund()
    } else {
      this.getUpiData()
    }
  }

  upiDataArray: any = []
  getUpiData() {
    let data = {
      bankType: "UPI"
    }
    let url = `admin/listBank`
    this.service.postApiFunc(url, data, 1).subscribe((res) => {

      if (res['responseCode'] == 200) {
        this.service.hideSpinner();
        this.upiDataArray = res['result']['docs']
        this.staffLength = res.result.total

      } else {
        this.staffLength = []
        this.service.hideSpinner();
        this.service.toasterErr(res['responseMessage'])
      }
    }, err => {
      this.staffLength = []
    })
  }


  addStaff() {
    this.router.navigate(['/add-new-staff'])
  }
  editStaff(id) {
    this.router.navigate(['/edit-staff'], { queryParams: { id } })
  }
  // Delete / Block Function
  openModal(action, userId, status) {
    this.bankDetailId = userId;
    this.action = action;
    this.status = status
    if (action == 'DELETE') {
      $('#deleteModal').modal('show')

    } else if (action == 'BLOCK') {
      $('#block').modal('show')
    }
    else {
      $('#active').modal('show')
    }
  }

  deleteFunction() {
    $('#deleteModal').modal('hide')
    let data = {
      "ipAddress": this.ipAddress.ip,
      "primaryIdCommonPerRequest": this.userID
    }
    this.service.post('account/admin/user-management/detele-staff', data).subscribe((res) => {
      this.getStaffList();
    })
  }


  // Get List of staff
  getStaffList() {

    let data = {
      bankType: "BANK"
    }
    var url = `admin/listBank`;
    this.service.showSpinner();
    this.service.postApiFunc(url, data, 1).subscribe(res => {
      this.service.hideSpinner();
      if (res['responseCode'] == 200) {
        this.staffList = res.result.docs;
        this.staffLength = res.result.total

      }
    }, err => {
      this.staffList = []
      this.service.hideSpinner();
      // this.service.toasterErr(err.error.responseMessage);
      // if (err['responseCode'] == '401') {
      //   this.service.onLogout();
      //   this.service.toasterErr('Unauthorized Access');
      // } else {
      //   this.service.toasterErr('Something Went Wrong');
      // }
    })
  }

  search() {

    if (this.staffForm.value.toDate) {
      let data = {
        page: this.pageNumber,
        limit: this.limit,
        fromDate: new Date(this.staffForm.value.fromDate).toISOString(),
        toDate: new Date(this.staffForm.value.toDate).toISOString()
      }
      var url = `admin/listBank`;
      this.service.postApiFunc(url, data, 1).subscribe((res) => {
        if (res['responseCode'] == 200) {
          this.staffList = res['result']['docs'];
          this.staffLength = res.result.total;
          this.service.hideSpinner();
        }
        else {
          this.staffList = [];
          this.service.hideSpinner();
        }

      }, err => {
        this.staffList = []
      })
    }
    else if (this.staffForm.value.search) {
      let data = {
        "page": "0",
        "limit": "10",
        "search": this.staffForm.value.search,
      }
      var url = "admin/listBank";
      this.service.postApiFunc(url, data, 1).subscribe((res: any) => {
        this.staffList = null;
        if (res.responseCode == 200) {
          this.staffList = res['result']['docs'];
          this.staffLength = res.result.total;
          this.service.hideSpinner();
        }
        else {
          this.staffList = [];
          this.service.hideSpinner();
        }
      }, err => {
        this.staffList = []
      })
    }
    else if (this.staffForm.value.role) {
      this.service.showSpinner();
      let data = {
        "page": "0",
        "pageSize": "10",
        "role": this.staffForm.value.role,
      }
      var url = "account/admin/search-and-filter-bank-account-list";
      this.service.post(url, data).subscribe((res: any) => {
        this.staffList = null;
        if (res.status == 200) {
          this.staffList = res['data']['list'];
          this.staffLength = this.staffList.length;
          this.service.hideSpinner();
        }
        else {
          this.staffList = [];
          this.service.hideSpinner();
        }
      }, err => {
        this.staffList = []
      })
    }
    else if (this.staffForm.value.status) {
      this.service.showSpinner();
      let data = {
        "page": "0",
        "pageSize": "10",
        "bankStatus": this.staffForm.value.status,
      }
      var url = `account/admin/search-and-filter-bank-account-list`;
      this.service.post(url, data).subscribe((res: any) => {
        this.staffList = null;
        if (res.status == 200) {
          this.staffList = res['data']['list'];
          this.staffLength = this.staffList.length;
          this.service.hideSpinner();
        }
        else {
          this.staffList = [];
          this.service.hideSpinner();
        }
      }, err => {
        this.staffList = []
      })
    }
    else if (this.staffForm.invalid) {
      this.service.showSpinner();
      this.getStaffList();
      this.service.hideSpinner();
    }
  }

  pagination(page) {
    this.pageNumber = page;
    console.log(page);

    // this.getUserList()
    this.getStaffList()
    // this.getUserCompanyList()


  }

  viewStaff(id) {
    this.router.navigate(['/view-staff'], { queryParams: { id } })
  }

  performAction() {
    $('#block').modal('hide')
    $('#active').modal('hide')
    let data = {
      bankId: this.bankDetailId,
      approveReject: this.status
    }
    var url = 'admin/approveRejectBank';
    this.service.putApi(url, data, 1).subscribe((res) => {
      this.service.toasterSucc('Rejected')
      this.getStaffList();
    })
  }

  performActionActive() {
    $('#active').modal('hide')
    var url = 'account/admin/approve-or-reject-bank-account?bankDetailId=' + (this.bankDetailId) + '&bankStatus=' + ('APPROVED');
    this.service.post(url, '').subscribe((res) => {
      this.service.toasterSucc('Approved')
      this.getStaffList();
    })
  }

  reset() {
    this.staffForm.reset();
    this.getStaffList();
    this.staffForm.get('status').setValue('All')
  }

  /**************** Date managing***************/
  public myDatePickerOptions: IMyDpOptions = {
    dateFormat: 'yyyy-mm-dd',
    editableDateField: false,
    openSelectorOnInputClick: false,
    disableSince: { year: 0, month: 0, day: 0 }
  };
  public toDate: IMyDpOptions = {
    dateFormat: 'yyyy-mm-dd',
    editableDateField: false,
    openSelectorOnInputClick: false,
    disableUntil: { year: 0, month: 0, day: 0 }
  };

  onDateChanged() {
    let d = new Date();
    let copy1 = this.getCopyOfOptions();
    copy1.disableSince = {
      year: d.getFullYear(),
      month: d.getMonth() + 1,
      day: d.getDate()
    };
    this.myDatePickerOptions = copy1;
  }
  //Returns copy of myDatePickerOptions
  getCopyOfOptions(): IMyDpOptions {
    return JSON.parse(JSON.stringify(this.myDatePickerOptions));
  }


  public onChange(event: IMyDateModel) {
    if (event.formatted) {
      this.beDisable = false
      let d: Date = new Date(event.jsdate.getTime());
      d.setDate(d.getDate() - 1);
      let copy: IMyDpOptions = this.getCopyOfToDateOpt();
      copy.disableUntil = {
        year: d.getFullYear(),
        month: d.getMonth() + 1,
        day: d.getDate()
      };
      this.toDate = copy;


    }

  }
  getCopyOfToDateOpt(): IMyDpOptions {
    return JSON.parse(JSON.stringify(this.toDate));
  }

  //export User
  exportAsXLSX() {
    let dataArr = [];
    this.staffList.forEach((element, ind) => {

      dataArr.push({
        "ID": ind + 1,
        "Name": element.firstName + '' + element.lastName ? element.lastName : '',
        "Role": element.role ? element.role : 'N/A',
        "Created At": element.createdTime ? element.createdTime.slice(0, 10) : 'N/A',
      })
    })

    this.service.exportAsExcelFile(dataArr, 'Staff_list');
  }

}
