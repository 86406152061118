import { ActivatedRoute, Router } from '@angular/router';
import { Component, OnInit } from '@angular/core';
import { ServiceService } from 'src/app/service.service';

@Component({
  selector: 'app-fd-view',
  templateUrl: './fd-view.component.html',
  styleUrls: ['./fd-view.component.css']
})
export class FdViewComponent implements OnInit {
  fixedDepositId: any
  editData: any
  tab: any
  id: any
  fixedStack: any;
  constructor(private router: Router, private activatedroute: ActivatedRoute, public commonService: ServiceService) { }

  ngOnInit() {
    this.activatedroute.queryParams.subscribe((res) => {
      this.id = res.id
      this.fixedStack = res.fixedStack
      console.log(this.fixedStack);

    })
    this.fdView()
  }
  back() {
    this.router.navigate(['/fd-list'])
  }

  timeDuration(t1, t2) {
    let date1 = new Date(t1);
    let date2 = new Date(t2);
    let time = date2.getTime() - date1.getTime();
    let days = time / (1000 * 3600 * 24);
    return days;
  }

  getIntrest(i1, i2) {
    let I1 = i1
    let I2 = i2
    let difference = ((I1 - I2) * 100) / I2
    return difference;
  }


  fdView() {

    this.commonService.showSpinner()
    this.commonService.getApi("admin/viewStakeRequest?stakeId=" + this.id, 1).subscribe((res: any) => {
      if (res.responseCode = 200) {
        this.editData = res.result;
        this.commonService.hideSpinner()

      }
    }, err => {

      this.commonService.hideSpinner();
      if (err['responseCode'] == '401') {
        //  this.commonService.onLogout();
        this.commonService.toasterErr('Unauthorized Access');
      } else {
        this.commonService.toasterErr('Something Went Wrong');
      }
    })

  }
  toFixed(number, toLength: number) {
    return Number(number).toFixed(toLength)
  }

  _id: any
  status: any
  rstatus: any

  approveStaking(stackStatus) {
    // let url = `wallet/admin/fee-management/emergeny-approvel-stake?documentStatus=${this.status}&fixedDepositId=${this.id}&approveStatus=${stackStatus}`
    let url = `admin/approveRejectStake`
    let data = {
      stakeId: this.id,
      approveReject: 'APPROVE',
      interest: 'WITH_INTEREST'
    }
    // this.service.showSpinner();
    this.commonService.putApi(url, data, 1).subscribe((res: any) => {
      if (res.responseCode == 200) {
        this.commonService.hideSpinner();
        // this.commonService.toasterSucc(res.message)
        this.commonService.toasterSucc('Unstake approved successfully.')
        this.router.navigate(['/fd-list'])
      }
      else {
        this.commonService.hideSpinner();
        this.commonService.toasterErr(res.responseMessage)
      }
    }, (error) => {
      this.commonService.hideSpinner();
    })
  }


}
