import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-view-user-trading',
  templateUrl: './view-user-trading.component.html',
  styleUrls: ['./view-user-trading.component.css']
})
export class ViewUserTradingComponent implements OnInit {

  constructor() { }

  ngOnInit() {
  }

}
