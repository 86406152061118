import { Component, OnInit } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { ServiceService } from 'src/app/service.service';

declare var $: any;
@Component({
  selector: 'app-emergency-staking',
  templateUrl: './emergency-staking.component.html',
  styleUrls: ['./emergency-staking.component.css']
})
export class EmergencyStakingComponent implements OnInit {

  modalForm: FormGroup
  userId: any;
  viewData: any;
  documentId: any;
  kycId: any;
  kycStatus: any;
  ima: any
  docData: any
  id: any
  status: any
  status1: any
  editData: any
  reason: any
  rstatus: any

  constructor(public router: Router, public service: ServiceService, public active: ActivatedRoute) {
    this.active.queryParams.subscribe((params) => {
      this.id = params.id
    })
  }

  ngOnInit(): void {
    this.modalForm = new FormGroup({
      'reason': new FormControl('', Validators.required)
    })
    this.fdView();

  }

  fdView() {

    this.service.showSpinner()
    this.service.getApi("admin/viewStakeRequest?stakeId=" + this.id, 1).subscribe((res: any) => {
      if (res.responseCode = 200) {
        this.editData = res.result;
        this.service.hideSpinner()

      }
    }, err => {

      this.service.hideSpinner();
      if (err['responseCode'] == '401') {
        //  this.service.onLogout();
        this.service.toasterErr('Unauthorized Access');
      } else {
        this.service.toasterErr('Something Went Wrong');
      }
    })

  }

  timeDuration(t1, t2) {
    let date1 = new Date(t1);
    let date2 = new Date(t2);
    let time = date2.getTime() - date1.getTime();
    let days = time / (1000 * 3600 * 24);
    return days;
  }

  getIntrest(i1, i2) {
    let I1 = i1
    let I2 = i2
    let difference = ((I1 - I2) * 100) / I2
    return difference;
  }


  // userDetail: any
  // showUserDetail() {
  //   this.service.showSpinner()
  //   var url = "account/admin/user-management/user-details?userId=" + this.userId
  //   this.service.get(url).subscribe((res: any) => {
  //     if (res.status == 200) {
  //       this.userDetail = res.data;
  //       this.service.hideSpinner();
  //     }

  //   }, err => {

  //     this.service.hideSpinner();
  //     if (err['status'] == '401') {
  //       this.service.onLogout();
  //       this.service.toasterErr('Unauthorized Access');
  //     } else {
  //       this.service.toasterErr('Something Went Wrong');
  //     }
  //   })
  // }
  _id: any
  getStatus(status: any, id: any) {
    this.status = status;
    this._id = id
    if (this.status == 'APPROVE') {
      $('#approveModal').modal('show');
    } else if (this.status == 'REJECT') {
      $('#rejectModal').modal('show');
    } else {
      $('#approveModal').modal('hide');
      $('#rejectModal').modal('hide');
    }
  }


  // getStatus(status: any) {
  //   this.status = status;
  //   console.log(this.status);
  //   $('#approveModal').modal('show');

  // }
  // getStatus1(status: any) {
  //   this.status1 = status;
  //   console.log(this.status);
  //   $('#rejectModal').modal('show');
  // }

  // approve staking

  approveStaking(stackStatus) {
    // let url = `wallet/admin/fee-management/emergeny-approvel-stake?documentStatus=${this.status}&fixedDepositId=${this.id}&approveStatus=${stackStatus}`
    let url = `admin/approveRejectStake`
    let data = {
      stakeId: this._id,
      approveReject: this.status,
      reason: this.rstatus,
      interest: stackStatus
    }
    // this.service.showSpinner();
    this.service.putApi(url, data, 1).subscribe((res: any) => {
      if (res.responseCode == 200) {
        this.service.hideSpinner();
        // this.service.toasterSucc(res.message)
        this.service.toasterSucc('Emergency Staking approved successfully.')
        $('#approveModal').modal('hide');
        $('#rejectModal').modal('hide');
        this.router.navigate(['/fd-list'])
      }
      else {
        $('#approveModal').modal('hide');
        $('#rejectModal').modal('hide');

        this.service.hideSpinner();
        this.service.toasterErr(res.responseMessage)
      }
    }, (error) => {
      $('#approveModal').modal('hide');
      $('#rejectModal').modal('hide');

      this.service.hideSpinner();
    })
  }

  // reject Staking

  // rejectStaking() {
  //   let data = {
  //     'reason': this.rstatus
  //   }
  //   let url = `wallet/admin/fee-management/emergeny-approvel-stake?documentStatus=${this.status1}&fixedDepositId=${this.id}`
  //   this.service.showSpinner();
  //   this.service.post(url, data).subscribe((res: any) => {
  //     if (res.status == 200) {
  //       console.log(this.rstatus);

  //       this.service.hideSpinner();
  //       // this.service.toasterSucc(res.message)
  //       this.service.toasterSucc('Emergency Staking reject successfully.')
  //       $('#rejectModal').modal('hide');
  //       this.router.navigate(['/fd-list'])
  //     }
  //     else {
  //       console.log(this.rstatus);

  //       $('#rejectModal').modal('hide');

  //       this.service.hideSpinner();
  //       this.service.toasterErr(res.message)
  //     }
  //   }, (error) => {
  //     console.log(this.rstatus);

  //     $('#rejectModal').modal('hide');

  //     this.service.hideSpinner();
  //   })
  // }



  image() {
    $('#imgg').modal('show')
  }

  backimage() {
    $('#backimgg').modal('show')
  }

}
