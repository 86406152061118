import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';

import { FormGroup, FormControl, Validators } from '@angular/forms';
import { ServiceService } from 'src/app/service.service';
declare var $: any
@Component({
  selector: 'app-fd-list',
  templateUrl: './fd-list.component.html',
  styleUrls: ['./fd-list.component.css']
})
export class FdListComponent implements OnInit {
  selected: string = "btc";
  coinlist: any = [];
  coinListArr: any = [];
  transferForm: FormGroup;
  loopData: any = [];
  txndata: any = []
  lengthTotal: any;
  minDate: any;
  itemsPerPage = 10;
  currentPage: number = 1;
  totalItems: any
  flip: string = "List";
  stakStatus: any


  today: any = new Date().toISOString().slice(0, 16)

  constructor(
    private router: Router,
    public service: ServiceService,
    private activatedroute: ActivatedRoute
  ) { }

  ngOnInit() {
    this.checkTransferFormValidations();

    // this.sidemenu();
    // this.getCoinWalletList();
    this.getFdList()
  }





  copyToClipboard(item) {

    this.service.toasterSucc('Copied')
    document.addEventListener('copy', (e: ClipboardEvent) => {
      e.clipboardData.setData('text/plain', (item));
      e.preventDefault();
      document.removeEventListener('copy', null);
    });
    document.execCommand('copy');
  }
  isSearched: boolean = false;

  pagination(page) {
    // this.totalRecords = [];
    console.log("jh", page);
    this.currentPage = page;
    this.ekFunctionZindgiKa();
    console.log("jh", this.currentPage);
    if (this.isSearched) {


      this.ekFunctionZindgiKa();
    } else {
      // this.getFdList();
    }
  }
  checkTransferFormValidations() {
    this.transferForm = new FormGroup({
      'fromDate': new FormControl(''),
      'toDate': new FormControl(''),
      'coinNameSe': new FormControl(''),
      'status': new FormControl(''),
      'stakingStatus': new FormControl('')
    })
  }
  ekFunctionZindgiKa() {
    // let fromDate = new Date(this.transferForm.value.fromDate).toISOString;
    // let toDate = new Date(this.transferForm.value.toDate).toISOString;
    let coinNameSe = this.transferForm.value.coinNameSe;
    let status = this.transferForm.value.status;
    let stakingStatus = this.transferForm.value.stakingStatus;
    // let url = `wallet/get-Fixed-Deposit-Details-by-pagination?page=${this.currentPage - 1}&pageSize=${this.itemsPerPage}${this.transferForm.value.fromDate ? "&fromDate=" + fromDate : ""}${this.transferForm.value.toDate ? "&toDate=" + toDate : ""}${this.transferForm.value.coinNameSe ? "&email=" + coinNameSe : ""}${this.transferForm.value.status ? "&fixedStatus=" + status : ""}${this.transferForm.value.stakingStatus ? "&fixedStack=" + stakingStatus : ""}`
    let url = `admin/listStakeRequest?page=${this.currentPage}&limit=${this.itemsPerPage}${this.transferForm.value.fromDate ? "&fromDate=" + this.transferForm.value.fromDate : ""}${this.transferForm.value.toDate ? "&toDate=" + this.transferForm.value.toDate : ""}${this.transferForm.value.coinNameSe ? "&search=" + coinNameSe : ""}${this.transferForm.value.status ? "&stakeStatus=" + status : ""}${this.transferForm.value.stakingStatus ? "&stakeType=" + stakingStatus : ""}`
    //  let zindgi  =  `wallet/admin/transaction-history/get-all-transaction-history?amount=${this.transferForm.value.amount}&coinName=${this.transferForm.value.coinNameSe}&fromDate=${Math.round(new Date(this.transferForm.value.fromDate).getTime())}&page=${this.currentPage - 1}&pageSize=${this.itemsPerPage}&status=${this.transferForm.value.status}&toDate=${Math.round(new Date(this.transferForm.value.toDate).getTime())}&txnHash=${this.transferForm.value.searchTextTxnHash}&txnType=${this.transferForm.value.type}`
    this.service.getApi(url, 1).subscribe(
      (res: any) => {
        if (res.responseCode == 200) {
          this.FdListArray = res.result.docs;
          this.lengthTotal = res.result.total;
          this.service.toasterSucc(res.responseMessage)
        }
        //  this.totalRecords = res.data.totalCount;
        this.service.hideSpinner();
      },
      (err: any) => {
        this.FdListArray = []
        this.service.hideSpinner();
        this.service.toasterErr(err.error.responseMessage);
      }
    );
  }

  reset() {
    if (
      this.transferForm.value.fromDate ||
      this.transferForm.value.toDate ||
      this.transferForm.value.coinNameSe ||
      this.transferForm.value.status ||
      this.transferForm.value.stakingStatus

    ) {
      this.transferForm.reset({
        fromDate: "",
        toDate: "",
        coinNameSe: "",
        status: "",
        stakingStatus: ""

      });
      this.getFdList();
    }
  }
  getCoinWalletList() {

    this.service.showSpinner();
    // this.coinlist = [];
    this.service.get('wallet/coin/get-coin-list').subscribe((res: any) => {
      this.service.hideSpinner()
      if (res.status == 200) {
        // this.getAllTransactionHistory();
        this.coinlist = res.data;
        this.coinlist.forEach(element => {
          this.coinListArr.push(element.coinShortName);
        });
      }
      else {
        this.service.hideSpinner();
        // this.getAllTransactionHistory();
      }
    }, (error) => {
      this.service.hideSpinner();
      if (error['responseCode'] == 401) {
        this.service.onLogout();
        this.service.toasterErr('Unauthorized Access');
      }
      else {
        this.service.toasterErr('Something Went Wrong');
      }
    })
  }

  navigat(id, status) {
    this.router.navigate(['/fd-view'], { queryParams: { id: id, fixedStack: status } })
  }
  move(status: any) {
    this.router.navigate(['/fd-view'], { queryParams: { tab: status } })
  }

  //export User
  exportAsXLSX() {
    let dataArr = [];
    this.FdListArray.forEach((element, ind) => {

      dataArr.push({
        "S no": ind + 1,
        "Username": element.userDetails.firstName + '' + element.userDetails.lastName ? element.userDetails.firstName : '',
        "Email": element.userDetails.email ? element.userDetails.email : '',

        "Amount": element.price ? element.price : 'N/A',
        "Interest": element.interestRate ? element.interestRate : 'N/A',
        "Coin": element.coinId.coinName ? element.coinId.coinName : 'N/A',
        "Start Date": element.createdAt ? element.createdAt.slice(0, 10) : 'N/A',
        "End Date": element.fromDate ? element.fromDate.slice(0, 10) : 'N/A',
      })
      // let fhkds= JSON.stringify(dataArr)
    })

    this.service.exportAsExcelFile(dataArr, 'Staking list');
  }


  FdListArray: any = []
  getFdList() {
    let url = `admin/listStakeRequest?page=${this.currentPage}&limit=${this.itemsPerPage}`
    this.service.showSpinner()
    // this.loopData = []
    // this.lengthTotal = 0;
    this.service.getApi(url, 1).subscribe((res: any) => {
      if (res.responseCode == 200) {
        this.service.hideSpinner()
        this.FdListArray = res.result.docs;
        // this.lengthTotal = res['data'].allCountDetails
        this.lengthTotal = res.result.total
      }
    }, err => {
      this.service.hideSpinner();
      if (err.status >= 400) {
        this.service.toasterErr(err.error.responseMessage)
      }
    })
  }

  viewFd(fixedDepositId) {
    // alert(fixedDepositId)
    this.router.navigate(['/fd-view'], { queryParams: { fixedDepositId: fixedDepositId } })
  }
  isAssending: boolean = true;
  sortAgent(key) {
    if (this.isAssending) {
      this.FdListArray = this.FdListArray.sort((a, b) => {
        return a[key] > b[key] ? 1 : -1;
      });
    } else {
      this.FdListArray = this.FdListArray.sort((a, b) => {
        return a[key] < b[key] ? 1 : -1;
      });
    }
  }
}
