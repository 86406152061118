import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { ServiceService } from '../service.service';

@Component({
  selector: 'app-tutorial-view',
  templateUrl: './tutorial-view.component.html',
  styleUrls: ['./tutorial-view.component.css']
})
export class TutorialViewComponent implements OnInit {

  bannerId: any;
  skirtListArray: any;
  colorList: any = [];
  editData: any
  userId: string;
  constructor(private activatedroute: ActivatedRoute, public commonService: ServiceService) { }

  ngOnInit() {
    this.activatedroute.queryParams.subscribe((res) => {
      this.bannerId = res.bannerId;
    })
    this.viewBlog()
  }

  // edit faq english
  viewBlog() {
    this.commonService.showSpinner()
    this.commonService.getApi("admin/viewTutorial?tutorialId=" + this.bannerId, 0).subscribe((res: any) => {
      if (res.responseCode = 200) {
        this.editData = res.result;
        console.log(this.editData);

        this.commonService.hideSpinner()

      }
    }, err => {

      this.commonService.hideSpinner();
      if (err['responseCode'] == '401') {
        //  this.commonService.onLogout();
        this.commonService.toasterErr('Unauthorized Access');
      } else {
        this.commonService.toasterErr('Something Went Wrong');
      }
    })

  }

  getFileType(url) {
    return url.substring(url.lastIndexOf("."))
  }
}
