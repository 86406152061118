import { Component, OnInit } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { ServiceService } from '../service.service';

declare const $: any


@Component({
  selector: 'app-subscribers',
  templateUrl: './subscribers.component.html',
  styleUrls: ['./subscribers.component.css']
})
export class SubscribersComponent implements OnInit {
  [x: string]: any;

  bodyListArray: any = [];
  itemPerPage = 10;
  currentPage = 1;
  totalItems: any;
  staffForm: FormGroup;
  staffList: any;
  modalForm: FormGroup

  constructor(private router: Router, public service: ServiceService) {
    this.staffForm = new FormGroup({
      fromDate: new FormControl(''),
      toDate: new FormControl(''),
      search: new FormControl('')
    })

  }

  ngOnInit(): void {
    this.getStaffList()
    // this.search()
    this.modalForm = new FormGroup({
      'reason': new FormControl('', Validators.required)
    })
  }
  email: any
  mailSend(mail) {
    this.email = mail
    this.modalStatus = 'oneUser'
    $('#mailModal').modal('show')
  }
  modalStatus: any
  mailSend1(status) {
    this.modalStatus = status
    console.log(this.modalStatus);

    $('#mailModal').modal('show')
  }

  // getStaffList() {

  //   let data = {
  //     page: this.currentPage,
  //     limit: this.itemPerPage,
  //     fromDate: this.staffForm.value.fromDate ? this.staffForm.value.fromDate : "",
  //     toDate: this.staffForm.value.toDate ? this.staffForm.value.toDate : "",
  //     search: this.staffForm.value.search ? this.staffForm.value.search : "",
  //   }

  //   var url = `admin/subscribeList`;
  //   this.service.showSpinner();
  //   this.service.postApiFunc(url, data, 1).subscribe(res => {
  //     this.service.hideSpinner();
  //     if (res['responseCode'] == 200) {
  //       this.staffList = res.result.docs;
  //       this.staffLength = res.result.total

  //     }
  //   }, err => {
  //     this.service.toasterErr(err.error.responseMessage)
  //     this.service.hideSpinner();
  //   })
  // }


  getStaffList() {
    var url = `admin/subscribeList?page=${this.currentPage}&limit=${this.itemPerPage}${this.staffForm.value.fromDate ? "&fromDate=" + this.staffForm.value.fromDate : ""}${this.staffForm.value.toDate ? "&toDate=" + this.staffForm.value.toDate : ""}${this.staffForm.value.search ? "&search=" + this.staffForm.value.search : ""}`;
    this.service.showSpinner();
    this.service.getApi(url, 1).subscribe(res => {
      this.service.hideSpinner();
      if (res['responseCode'] == 200) {
        this.staffList = res.result.docs;
        this.staffLength = res.result.total

      }
    }, err => {
      this.staffList = []
      this.service.toasterErr(err.error.responseMessage)
      this.service.hideSpinner();
    })
  }

  sendMail() {
    let data = {
      email: this.email,
      message: this.modalForm.value.reason
    }
    var url = `admin/replyToSubscriber`;
    this.service.showSpinner();
    this.service.postApiFunc(url, data, 1).subscribe(res => {
      if (res['responseCode'] == 200) {
        this.service.hideSpinner();
        $('#mailModal').modal('hide')
        this.service.toasterSucc(res.responseMessage)
      }
    }, err => {
      this.service.toasterErr(err.error.responseMessage)
      this.service.hideSpinner();
    })

  }

  sendMailToAllUser() {
    let data = {
      // email: this.email,
      message: this.modalForm.value.reason
    }
    var url = `admin/replyToSubscriber`;
    this.service.showSpinner();
    this.service.postApiFunc(url, data, 1).subscribe(res => {
      if (res['responseCode'] == 200) {
        this.service.hideSpinner();
        $('#mailModal').modal('hide')
        this.service.toasterSucc(res.responseMessage)
      }
    }, err => {
      this.service.toasterErr(err.error.responseMessage)
      this.service.hideSpinner();
    })

  }

  reset() {
    if (
      this.staffForm.value.fromDate ||
      this.staffForm.value.toDate ||
      this.staffForm.value.search
    ) {
      this.staffForm.reset({
        fromDate: "",
        toDate: "",
        search: "",
      });
      this.getStaffList();
    }
  }

}
