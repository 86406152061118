import { Component, OnInit } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { IMyDateModel, IMyDpOptions } from 'mydatepicker';
import { ServiceService } from 'src/app/service.service';
declare var $: any
@Component({
  selector: 'app-list-tokenomics',
  templateUrl: './list-tokenomics.component.html',
  styleUrls: ['./list-tokenomics.component.css']
})
export class ListTokenomicsComponent implements OnInit {

  pageNumber: number = 1;
  totalRecord: any;
  pageSize: number = 10
  totalItems: any;
  fromDate: any = ''
  beDisable: boolean = true;
  userForm: FormGroup;
  userID: any;
  IsToDate: boolean = true;
  calender: any = { todate: '', formdate: '' }
  action: any;
  userList: any = [];
  searchText: string = ''
  userDetail: any = {};
  space: string = ' ';
  convertFormat: any;
  newArray: any = [];
  newFromDate: number;
  newToDate: any;
  countryList: any = []
  minAge: Date;
  tab: any = "user"
  tab1: any = ''
  constructor(private router: Router, public service: ServiceService) {

  }

  ngOnInit() {
    this.ekFunctionZindgiKa();
  }
  fromDateVali: any = ''
  fromdate() {
    this.fromDateVali = new Date(this.userForm.value.startdate)
    this.fromDateVali = this.fromDateVali.getTime()
    console.log(this.fromDateVali);

  }
  userType1: any = "COMPANY"
  userList1: any
  userList2: any
  ekFunctionZindgiKa() {
    let url = `user/tokonomicsDetails`
    this.service.getApi(url, 1).subscribe(
      (res: any) => {
        if (res.responseCode == 200) {
          this.userList = res.result[0].data;
          this.userList1 = res.result[0].currencies;
          this.userList2 = res.result[0];
          this.totalItems = res.result.total;
          console.log(this.userList._id);

          // this.service.toasterSucc(res.responseMessage)
        } else {

          this.service.hideSpinner();
          this.service.toasterErr(res.responseMessage)
        }
      },
      (err: any) => {
        this.userList = []
        this.service.hideSpinner();
        this.service.toasterErr(err.error.responseMessage);
      }
    );
  }

  disableSearchbtn() {

    if (this.userForm.value.startdate || this.userForm.value.enddate || this.userForm.value.country || this.userForm.value.status || this.userForm.value.searchText) {
      return false
    }
    else {
      return true
    }

  }

  reset() {
    if (
      this.userForm.value.searchText ||
      this.userForm.value.startdate ||
      this.userForm.value.enddate ||
      this.userForm.value.sedan ||
      this.userForm.value.hatch ||
      this.userForm.value.suv ||
      this.userForm.value.country ||
      this.userForm.value.status


    ) {
      this.userForm.reset({
        startdate: "",
        enddate: "",
        searchText: "",
        sedan: '',
        hatch: '',
        suv: '',
        country: '',
        status: ''
      });
      this.ekFunctionZindgiKa();
    }
  }

  pagination(page) {
    this.pageNumber = page;
    console.log(page);

    // this.getUserList()
    this.ekFunctionZindgiKa()
    // this.getUserCompanyList()


  }
  // get country list
  getCountryList() {
    this.service.getApi('country/country', 0).subscribe((res) => {
      if (res['responseCode'] == 200) {
        this.countryList = res['result']['countries']
      }
    })
  }
  /**************** Date managing***************/
  public myDatePickerOptions: IMyDpOptions = {
    dateFormat: 'yyyy-mm-dd',
    editableDateField: false,
    openSelectorOnInputClick: false,
    disableSince: { year: 0, month: 0, day: 0 }
  };
  public toDate: IMyDpOptions = {
    dateFormat: 'yyyy-mm-dd',
    editableDateField: false,
    openSelectorOnInputClick: false,
    disableUntil: { year: 0, month: 0, day: 0 }
  };

  onDateChanged() {
    let d = new Date();
    let copy1 = this.getCopyOfOptions();
    copy1.disableSince = {
      year: d.getFullYear(),
      month: d.getMonth() + 1,
      day: d.getDate()
    };
    this.myDatePickerOptions = copy1;
  }
  //Returns copy of myDatePickerOptions
  getCopyOfOptions(): IMyDpOptions {
    return JSON.parse(JSON.stringify(this.myDatePickerOptions));
  }


  public onChange(event: IMyDateModel) {
    if (event.formatted) {
      this.beDisable = false
      let d: Date = new Date(event.jsdate.getTime());
      d.setDate(d.getDate() - 1);
      let copy: IMyDpOptions = this.getCopyOfToDateOpt();
      copy.disableUntil = {
        year: d.getFullYear(),
        month: d.getMonth() + 1,
        day: d.getDate()
      };
      this.toDate = copy;
    }

  }
  getCopyOfToDateOpt(): IMyDpOptions {
    return JSON.parse(JSON.stringify(this.toDate));
  }
  /*******************Date managing Ends Here**************/
  // Delete / Block Function
  openModal(action, userId) {
    this.action = action;
    this.userID = userId;
    console.log(this.userID + "================" + this.action);

    if (action == 'DELETE') {
      $('#deleteModal').modal('show')

    } else if (action == 'BLOCK') {
      $('#block').modal('show')
    }
    else {
      $('#active').modal('show')
    }
  }
  //User Details
  userdetails(userId, email) {
    this.router.navigate(['/user-details/'], { queryParams: { userId, email } })
  }
  walletdetail(userId) {
    this.router.navigate(['walletdetails/' + userId])
  }

  //export User
  exportAsXLSX() {
    let dataArr = [];
    this.userList.forEach((element, ind) => {

      dataArr.push({
        "S no": ind + 1,
        "Name": element.firstName + '' + element.lastName ? element.firstName : '',
        "Email": element.email ? element.email : '',

        "Mobile Number": element.mobileNumber ? element.mobileNumber : 'N/A',
        "Country": element.country ? element.country : 'N/A',
        "Status": element.status ? element.status : 'N/A',
        "Registration Date & Time ": element.createdAt ? element.createdAt.slice(0, 10) : 'N/A',
      })
      // let fhkds= JSON.stringify(dataArr)
    })

    this.service.exportAsExcelFile(dataArr, 'User list');
  }
  formdate() {
    this.fromDate = new Date(this.calender.formdate)
    this.fromDate = this.fromDate.getTime()
    this.IsToDate = false;

  }

}
