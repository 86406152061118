import { ListTokenomicsComponent } from './tokenomics-management/list-tokenomics/list-tokenomics.component';
import { TutorialAddComponent } from './tutorial-add/tutorial-add.component';
import { TutorialEditComponent } from './tutorial-edit/tutorial-edit.component';
import { TutorialViewComponent } from './tutorial-view/tutorial-view.component';
import { TutorialListComponent } from './tutorial-list/tutorial-list.component';
import { SubscribersViewComponent } from './subscribers-view/subscribers-view.component';
import { SubscribersComponent } from './subscribers/subscribers.component';
import { OtpVarificationComponent } from './otp-varification/otp-varification.component';
import { AdminBankListComponent } from './feedback-management/admin-bank-list/admin-bank-list.component';
import { StakingEditComponent } from './staking-management/staking-edit/staking-edit.component';
import { StakingAddComponent } from './staking-management/staking-add/staking-add.component';
import { EmergencyStakingComponent } from './fixed-deposit-management/emergency-staking/emergency-staking.component';
import { EditAipPercentComponent } from './aip-management/edit-aip-percent/edit-aip-percent.component';
import { AddAipPercentComponent } from './aip-management/add-aip-percent/add-aip-percent.component';
import { ListAipPercentageComponent } from './aip-management/list-aip-percentage/list-aip-percentage.component';
import { EditBannerComponent } from './banner-management/edit-banner/edit-banner.component';
import { ViewBannerComponent } from './banner-management/view-banner/view-banner.component';
import { AddBannerComponent } from './banner-management/add-banner/add-banner.component';
import { NgModule, Component } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { LoginComponent } from './login/login.component';
import { ForgotPasswordComponent } from './forgot-password/forgot-password.component';
import { DashboardComponent } from './dashboard/dashboard.component';
import { UserManagementComponent } from './user-management/user-management.component';
import { MarketManagementComponent } from './market-management/market-management.component';
import { SettingComponent } from './setting/setting.component';
import { SubAdminManagementComponent } from './sub-admin-management/sub-admin-management.component';
import { WalletManagementComponent } from './wallet-management/wallet-management.component';
import { StaticsContentComponent } from './statics-content/statics-content.component';
import { AboutUsComponent } from './about-us/about-us.component';
import { TermAndServiceComponent } from './term-and-service/term-and-service.component';
import { PrivacyComponent } from './privacy/privacy.component';
import { ExchangeManagementComponent } from './exchange-management/exchange-management.component';
import { PaymentAndTransactionComponent } from './payment-and-transaction/payment-and-transaction.component';
import { KycManagementComponent } from './kyc-management/kyc-management.component';
import { TicketManagementComponent } from './ticket-management/ticket-management.component';
import { AddWalletAddressComponent } from './add-wallet-address/add-wallet-address.component';
import { WithdrawlFeeComponent } from './withdrawl-fee/withdrawl-fee.component';
import { DailyLimitComponent } from './daily-limit/daily-limit.component';
import { WithdrawlLimitComponent } from './withdrawl-limit/withdrawl-limit.component';
import { PrevilageSettingComponent } from './previlage-setting/previlage-setting.component';
import { ResetPasswordComponent } from './reset-password/reset-password.component';
import { MyProfileComponent } from './my-profile/my-profile.component';
import { EditProfileComponent } from './edit-profile/edit-profile.component';
import { BannerSettingComponent } from './banner-setting/banner-setting.component';
import { WebsiteContentSettingComponent } from './website-content-setting/website-content-setting.component';
import { KycActionPageComponent } from './kyc-action-page/kyc-action-page.component';
import { PageNotFoundComponent } from './page-not-found/page-not-found.component';
import { FiatManagementComponent } from './fiat-management/fiat-management.component';
// import { FeeManagementComponent } from './fee-management/fee-management.component';
import { HotcoldwalletManagementComponent } from './hotcoldwallet-management/hotcoldwallet-management.component';
import { ViewtransactionComponent } from './viewtransaction/viewtransaction.component';
import { TradeManagementComponent } from './trade-management/trade-management.component';
import { DisputeManagementComponent } from './dispute-management/dispute-management.component';
import { TradeDetailsComponent } from './trade-details/trade-details.component';
import { DisputeTradeDetailsComponent } from './dispute-trade-details/dispute-trade-details.component';
import { CreateSubadminComponent } from './create-subadmin/create-subadmin.component';
import { UserDetailsComponent } from './user-details/user-details.component';
import { DocumentDetailsComponent } from './document-details/document-details.component';
import { ProfitLossComponent } from './profit-loss/profit-loss.component';
import { EnquireManagementComponent } from './enquire-management/enquire-management.component';
import { WalletdetailsComponent } from './walletdetails/walletdetails.component';
import { StaffManagementComponent } from './staff-management/staff-management.component';
import { AddNewStaffComponent } from './add-new-staff/add-new-staff.component';
import { EditStaffComponent } from './edit-staff/edit-staff.component';
import { ViewStaffComponent } from './view-staff/view-staff.component';
import { FooterComponent } from './footer/footer.component';
import { AdminManagementComponent } from './admin-management/admin-management.component';
import { AddAdminComponent } from './add-admin/add-admin.component';
import { EditAdminComponent } from './edit-admin/edit-admin.component';
import { ViewAdminComponent } from './view-admin/view-admin.component';
import { UserManagementExchangeComponent } from './user-management-exchange/user-management-exchange.component';
import { ViewUserManagementExchangeComponent } from './view-user-management-exchange/view-user-management-exchange.component';
import { ViewUserManagementExchangeOfFeedbackComponent } from './view-user-management-exchange-of-feedback/view-user-management-exchange-of-feedback.component';
import { ChangePasswordComponent } from './change-password/change-password.component';
import { TakerMakerFeeComponent } from './taker-maker-fee/taker-maker-fee.component';
import { BankManagementComponent } from './bank-management/bank-management.component';
import { LogsManagementComponent } from './logs-management/logs-management.component';
import { AdvertisementManagementComponent } from './advertisement-management/advertisement-management.component';
import { AdvertisementDetailsComponent } from './advertisement-details/advertisement-details.component';
import { TicketDetailsComponent } from './ticket-details/ticket-details.component';
import { TicketReplyComponent } from './ticket-reply/ticket-reply.component';
import { EscrowManagementComponent } from './escrow-management/escrow-management.component';
import { DisclaimerComponent } from './disclaimer/disclaimer.component';
import { FeedbackListComponent } from './feedback-management/feedback-list/feedback-list.component';
import { FeedbackViewComponent } from './feedback-management/feedback-view/feedback-view.component';
import { FaqComponent } from './faq-management/faq/faq.component';
import { EditFaqComponent } from './faq-management/edit-faq/edit-faq.component';
import { ViewFaqComponent } from './faq-management/view-faq/view-faq.component';
import { AddFaqComponent } from './faq-management/add-faq/add-faq.component';
import { ListBlogComponent } from './blog-management/list-blog/list-blog.component';
import { AddBlogComponent } from './blog-management/add-blog/add-blog.component';
import { ViewBlogComponent } from './blog-management/view-blog/view-blog.component';
import { EditBlogComponent } from './blog-management/edit-blog/edit-blog.component';
import { StaticContentAddComponent } from './statics-content/static-content-add/static-content-add.component';
import { ListAnnouncementComponent } from './announcement-management/list-announcement/list-announcement.component';
import { AddAnnouncementComponent } from './announcement-management/add-announcement/add-announcement.component';
import { EditAnnouncementComponent } from './announcement-management/edit-announcement/edit-announcement.component';
import { ViewAnnouncementComponent } from './announcement-management/view-announcement/view-announcement.component';
import { PartnershipComponent } from './partnership/partnership.component';
import { ContactusManagementComponent } from './contactus-management/contactus-management.component';
import { CommunityAddComponent } from './community-management/community-add/community-add.component';
import { CommunityEditComponent } from './community-management/community-edit/community-edit.component';
import { CommunityListComponent } from './community-management/community-list/community-list.component';
import { CarrerCategoryListComponent } from './career-management/carrer-category-list/carrer-category-list.component';
import { CarrerCategoryAddComponent } from './career-management/carrer-category-add/carrer-category-add.component';
import { CareerCategoryEditComponent } from './career-management/career-category-edit/career-category-edit.component';
import { CareerCategoryViewComponent } from './career-management/career-category-view/career-category-view.component';
import { ListingUserListComponent } from './listing-managment/listing-user-list/listing-user-list.component';
import { CareerSubcategoryListComponent } from './career-subcategory-list/career-subcategory-list.component';
import { CareerSubcategoryAddComponent } from './career-subcategory-add/career-subcategory-add.component';
import { CareerSubcategoryViewComponent } from './career-subcategory-view/career-subcategory-view.component';
import { CareerSubcategoryEditComponent } from './career-subcategory-edit/career-subcategory-edit.component';
import { ListingUserViewComponent } from './listing-managment/listing-user-view/listing-user-view.component';
import { UserDeatilsNomineeComponent } from './user-deatils-nominee/user-deatils-nominee.component';
import { ManageFeeComponent } from './fee-management/manage-fee/manage-fee.component';
import { ContactusViewComponent } from './contactus-management/contactus-view/contactus-view.component';
import { FdListComponent } from './fixed-deposit-management/fd-list/fd-list.component';
import { FdViewComponent } from './fixed-deposit-management/fd-view/fd-view.component';
import { ListBannerComponent } from './banner-management/list-banner/list-banner.component';
import { FixedPercentageComponent } from './fixed-deposit-management/fixed-percentage/fixed-percentage.component';

import { ListingCoinComponent } from './listing-coin/listing-coin.component';
import { ManageCoinListComponent } from './manage-coin-list/manage-coin-list.component';
import { AddTeamComponent } from './team-management/add-team/add-team.component';
import { EditTeamComponent } from './team-management/edit-team/edit-team.component';
import { ViewTeamComponent } from './team-management/view-team/view-team.component';
import { ListTeamComponent } from './team-management/list-team/list-team.component';
import { FeeManageComponent } from './fee-management/fee-manage/fee-manage.component';
import { InstantSwapManagementComponent } from './instant-swap-management/instant-swap-management.component';
import { AddAipPlanComponent } from './aip-management/add-aip-plan/add-aip-plan.component';
import { ListAipPlanComponent } from './aip-management/list-aip-plan/list-aip-plan.component';
import { ListAipDetailsComponent } from './aip-management/list-aip-details/list-aip-details.component';
import { ViewAipDetailComponent } from './aip-management/view-aip-detail/view-aip-detail.component';
import { EditAipDetailComponent } from './aip-management/edit-aip-detail/edit-aip-detail.component';
import { StakingViewComponent } from './staking-management/staking-view/staking-view.component';
import { StakingListComponent } from './staking-management/staking-list/staking-list.component';
import { StandardTradingFeeComponent } from './fee-management/standard-trading-fee/standard-trading-fee.component';
import { MinWithdrawlAmountComponent } from './fee-management/min-withdrawl-amount/min-withdrawl-amount.component';
import { UpdateWithdrawlAmountComponent } from './fee-management/update-withdrawl-amount/update-withdrawl-amount.component';
import { MinTradingFeeComponent } from './fee-management/min-trading-fee/min-trading-fee.component';
import { TokenManagementComponent } from './token-management/token-management.component';
import { MyProfileContactUpdateComponent } from './my-profile-contact-update/my-profile-contact-update.component';
import { ListBuySellTokensComponent } from './Token-Buy/Sell-Management/list-buy-sell-tokens/list-buy-sell-tokens.component';
import { EditTokenomicsComponent } from './tokenomics-management/edit-tokenomics/edit-tokenomics.component';
import { AddTokenomicsComponent } from './tokenomics-management/add-tokenomics/add-tokenomics.component';
import { EditTokenomicsCurrencyComponent } from './tokenomics-management/edit-tokenomics-currency/edit-tokenomics-currency.component';


const routes: Routes = [
  {
    path: '',
    redirectTo: "/login",
    pathMatch: "full"
  },
  {
    path: 'login',
    component: LoginComponent
  },
  {
    path: 'forgot-password',
    component: ForgotPasswordComponent
  },
  {
    path: 'dashboard',
    component: DashboardComponent,
  },
  {
    path: 'user-management',
    component: UserManagementComponent
  },

  {
    path: 'market-management/:action',
    component: MarketManagementComponent
  },
  {
    path: 'setting',
    component: SettingComponent
  },
  {
    path: 'sub-admin-management',
    component: SubAdminManagementComponent
  },
  {
    path: 'wallet-management',
    component: WalletManagementComponent
  },
  {
    path: 'statics-content',
    component: StaticsContentComponent
  },
  {
    path: 'about-us/:id/:_id',
    component: AboutUsComponent
  },
  {
    path: 'term-and-service/:id/:_id',
    component: TermAndServiceComponent
  },
  {
    path: 'privacy/:id/:_id',
    component: PrivacyComponent
  },
  {
    path: 'disclaimer/:id/:_id',
    component: DisclaimerComponent
  },
  {
    path: 'exchange-management',
    component: ExchangeManagementComponent
  },
  {
    path: 'payment-and-transaction',
    component: PaymentAndTransactionComponent
  },
  {
    path: 'kyc-management',
    component: KycManagementComponent,
  },
  {
    path: 'ticket-management',
    component: TicketManagementComponent
  },
  {
    path: 'ticket-details/:id',
    component: TicketDetailsComponent
  },
  {
    path: 'ticket-reply/:id',
    component: TicketReplyComponent
  },
  {
    path: 'add-wallet-address',
    component: AddWalletAddressComponent
  },
  {
    path: 'withdrawl-fee',
    component: WithdrawlFeeComponent
  },
  {
    path: 'daily-limit',
    component: DailyLimitComponent
  },
  {
    path: 'withdrawl-limit',
    component: WithdrawlLimitComponent
  },
  {
    path: 'previlage-setting',
    component: PrevilageSettingComponent
  },
  {
    path: 'reset-password',
    component: ResetPasswordComponent
  },
  {
    path: 'my-profile',
    component: MyProfileComponent
  },
  { path: 'contactUs', component: ContactusManagementComponent },
  {
    path: 'edit-profile',
    component: EditProfileComponent
  },
  {
    path: 'banner-setting',
    component: BannerSettingComponent
  },
  {
    path: 'website-content-setting/:id',
    component: WebsiteContentSettingComponent
  },
  {
    path: 'kyc-action/:id',
    component: KycActionPageComponent
  },
  {
    path: 'fiat-management',
    component: FiatManagementComponent
  },
  { path: 'token-management', component: TokenManagementComponent },

  // {
  //   path: 'fee-management',
  //   component: ManageFeeComponent
  // },
  { path: 'manage-fee', component: ManageFeeComponent },
  { path: 'standard-trading-fee', component: StandardTradingFeeComponent },
  { path: 'min-withdrawl-amount', component: MinWithdrawlAmountComponent },
  { path: 'update-withdrawl-amount/:data', component: UpdateWithdrawlAmountComponent },
  { path: 'min-trading-fee/:data', component: MinTradingFeeComponent },
  {
    path: 'hotcoldwallet-management',
    component: HotcoldwalletManagementComponent
  },
  {
    path: 'viewtransaction/:id',
    component: ViewtransactionComponent
  },
  {
    path: 'user-details',
    component: UserDetailsComponent
  },
  {
    path: 'document-details/:id',
    component: DocumentDetailsComponent
  },
  {
    path: 'trade-management',
    component: TradeManagementComponent
  },
  {
    path: 'dispute-management',
    component: DisputeManagementComponent
  },
  {
    path: 'trade-details/:id',
    component: TradeDetailsComponent
  },
  {
    path: 'dispute-trade-details/:id/:chatId/:p2pId',
    component: DisputeTradeDetailsComponent
  },
  {
    path: 'create-subadmin',
    component: CreateSubadminComponent
  },
  {
    path: 'profit-loss',
    component: ProfitLossComponent
  },
  {
    path: 'enquiry-management',
    component: EnquireManagementComponent
  },
  {
    path: 'walletdetails/:id',
    component: WalletdetailsComponent
  },
  {
    path: 'staff-management',
    component: StaffManagementComponent
  },
  {
    path: 'add-new-staff',
    component: AddNewStaffComponent
  },
  {
    path: 'edit-staff',
    component: EditStaffComponent
  },
  {
    path: 'view-staff',
    component: ViewStaffComponent
  },
  {
    path: 'footer',
    component: FooterComponent
  },
  {
    path: 'admin-management',
    component: AdminManagementComponent
  },
  {
    path: 'add-admin',
    component: AddAdminComponent
  },
  {
    path: 'edit-admin',
    component: EditAdminComponent
  },
  {
    path: 'view-admin/:id',
    component: ViewAdminComponent
  },
  {
    path: 'user-management-exchange',
    component: UserManagementExchangeComponent
  },
  {
    path: 'view-user-management-exchange',
    component: ViewUserManagementExchangeComponent
  },
  {
    path: 'view-user-management-exchange-of-feedback',
    component: ViewUserManagementExchangeOfFeedbackComponent
  },
  {
    path: 'change-password',
    component: ChangePasswordComponent
  },
  {
    path: 'taker-maker-fee',
    component: TakerMakerFeeComponent
  },
  { path: 'category-list', component: CarrerCategoryListComponent },
  { path: 'category-add', component: CarrerCategoryAddComponent },
  { path: 'category-edit', component: CareerCategoryEditComponent },
  { path: 'category-view', component: CareerCategoryViewComponent },
  { path: 'listing-user', component: ListingUserListComponent },
  { path: 'listing-view', component: ListingUserViewComponent },
  { path: 'faq-management', component: FaqComponent },
  { path: 'add-faq', component: AddFaqComponent },
  { path: 'edit-faq', component: EditFaqComponent },
  { path: 'view-faq', component: ViewFaqComponent },
  { path: 'list-blog', component: ListBlogComponent },
  { path: 'add-blog', component: AddBlogComponent },
  { path: 'view-blog', component: ViewBlogComponent },
  { path: 'edit-blog', component: EditBlogComponent },
  { path: 'add-static', component: StaticContentAddComponent },
  { path: 'list-announcement', component: ListAnnouncementComponent },
  { path: 'add-announcement', component: AddAnnouncementComponent },
  { path: 'edit-announcement', component: EditAnnouncementComponent },
  { path: 'view-announcement', component: ViewAnnouncementComponent },
  { path: 'community-list', component: CommunityListComponent },
  { path: 'community-add', component: CommunityAddComponent },
  { path: 'community-edit', component: CommunityEditComponent },
  { path: 'partner/:id', component: PartnershipComponent },
  {
    path: 'logs-management',
    component: LogsManagementComponent
  },
  {
    path: 'advertisement-management',
    component: AdvertisementManagementComponent
  },
  {
    path: 'advertisement-details/:id',
    component: AdvertisementDetailsComponent
  },
  {
    path: 'bank-management',
    component: BankManagementComponent
  },
  {
    path: 'escrow-management',
    component: EscrowManagementComponent
  },
  { path: 'feedback-list', component: FeedbackListComponent },
  { path: 'feedback-view', component: FeedbackViewComponent },
  { path: 'career-subcat-list', component: CareerSubcategoryListComponent },
  { path: 'career-subcat-edit', component: CareerSubcategoryEditComponent },
  { path: 'career-subcat-add', component: CareerSubcategoryAddComponent },
  { path: 'career-subcat-view', component: CareerSubcategoryViewComponent },
  { path: 'nominee-view', component: UserDeatilsNomineeComponent },
  // {path:'contactUs',component:ContactusManagementComponent},
  { path: 'contactus-view', component: ContactusViewComponent },
  { path: 'fd-list', component: FdListComponent },
  { path: 'fd-view', component: FdViewComponent },
  { path: 'list-banner', component: ListBannerComponent },
  { path: 'add-banner', component: AddBannerComponent },
  { path: 'view-banner', component: ViewBannerComponent },
  { path: 'edit-banner', component: EditBannerComponent },
  { path: 'fd-percentage', component: FixedPercentageComponent },
  { path: 'listing-coin', component: ListingCoinComponent },
  { path: 'manage-coin', component: ManageCoinListComponent },
  { path: 'add-team', component: AddTeamComponent },
  { path: 'edit-team', component: EditTeamComponent },
  { path: 'view-team', component: ViewTeamComponent },
  { path: 'list-team', component: ListTeamComponent },
  { path: 'fee-management', component: FeeManageComponent },
  { path: 'instant-list', component: InstantSwapManagementComponent },
  { path: 'add-aip', component: AddAipPlanComponent },
  { path: 'list-aip', component: ListAipPlanComponent },
  { path: 'list-aip-percent', component: ListAipPercentageComponent },
  { path: 'add-aip-percent', component: AddAipPercentComponent },
  { path: 'edit-aip-percent', component: EditAipPercentComponent },
  { path: 'list-aip-details', component: ListAipDetailsComponent },
  { path: 'view-aip-details', component: ViewAipDetailComponent },
  { path: 'edit-aip-details', component: EditAipDetailComponent },
  { path: 'staking-list', component: StakingListComponent },
  { path: 'staking-view', component: StakingViewComponent },
  { path: 'emergency-staking', component: EmergencyStakingComponent },
  { path: 'staking-add', component: StakingAddComponent },
  { path: 'staking-edit', component: StakingEditComponent },
  { path: 'admin-bank-list', component: AdminBankListComponent },
  { path: 'otp', component: OtpVarificationComponent },
  { path: 'subscriber-list', component: SubscribersComponent },
  { path: 'subscriber-view', component: SubscribersViewComponent },
  { path: 'tutorial-list', component: TutorialListComponent },
  { path: 'tutorial-view', component: TutorialViewComponent },
  { path: 'tutorial-edit', component: TutorialEditComponent },
  { path: 'tutorial-add', component: TutorialAddComponent },
  { path: 'update-contact', component: MyProfileContactUpdateComponent },
  { path: 'list-buy-sell-tokens', component: ListBuySellTokensComponent },
  { path: 'list-tokenomics', component: ListTokenomicsComponent },
  { path: 'edit-tokenomics', component: EditTokenomicsComponent },
  { path: 'add-tokenomics', component: AddTokenomicsComponent },
  { path: 'edit-tokenomics-currency', component: EditTokenomicsCurrencyComponent },
  {
    path: '**',
    component: PageNotFoundComponent
  }
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class AppRoutingModule { }
