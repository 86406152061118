import { Component, OnInit } from '@angular/core';
import { ServiceService } from '../service.service';
import { ActivatedRoute, Router } from '@angular/router';

@Component({
  selector: 'app-privacy',
  templateUrl: './privacy.component.html',
  styleUrls: ['./privacy.component.css']
})
export class PrivacyComponent implements OnInit {
  id: any;
  about: any;
  dataa: any = [];
  pagekey
  title: any = "Privacy Policy"
  discription: any

  constructor(public service: ServiceService, public route: ActivatedRoute, private router: Router) { }

  ngOnInit() {
    this.route.params.subscribe(x => {

      this.pagekey = x['_id'];

    })
    this.getListCode();
  }

  // Get List Code by admin
  getListCode() {
    this.service.showSpinner();
    // http://182.72.203.244:3062/static/get-static-page-data-by-page-key?=About%20Us
    // static/get-static-page-data-by-page-key?pageKey=1
    this.service.getApi('static/static/PrivacyPolicy', 0).subscribe(res => {

      this.service.hideSpinner();
      if (res['responseCode'] == 200) {
        this.dataa = res['result'];
        // this.about = data.filter(x=>(x.staticContentId == this.id))
        // setTimeout(() => {
        //   this.dataa = res['result'][2];
        //   this.discription = res.result[3].description
        // }, 500);
      }
    }, err => {

      this.service.hideSpinner();
      if (err['responseCode'] == '401') {
        this.service.toasterErr('Unauthorized Access');
        this.service.onLogout();
      } else {
        this.service.toasterErr('Something Went Wrong');
      }
    })
  }

  // Save Abou Us Functionality
  saveAboutUS() {
    var apiReq = {
      "_id": this.pagekey,
      "title": this.dataa.title,
      "description": this.dataa.description
    }
    this.service.showSpinner();
    this.service.putApi('static/static', apiReq, 0).subscribe(res => {

      this.service.hideSpinner();
      if (res['responseCode'] == 200) {
        this.getListCode();
        this.router.navigate[('/statics-content')]

        this.service.toasterSucc('Privecy policy Updated Successfully')
      } else {
        this.service.toasterErr('Privecy policy Not Updated')
      }
    }, err => {

      this.service.hideSpinner();
      if (err['responseCode'] == '401') {
        this.service.onLogout();
        this.service.toasterErr('Unauthorized Access');
      } else {
        this.service.toasterErr('Something Went Wrong');
      }
    })
  }
}
