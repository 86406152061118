import { Component, OnInit } from '@angular/core';
import { ServiceService } from '../service.service';
import { FormGroup, FormControl, Validators } from '@angular/forms';
// import { SidemenuComponent } from '../sidemenu/sidemenu.component';
import { Router } from '@angular/router';

@Component({
  selector: 'app-edit-profile',
  templateUrl: './edit-profile.component.html',
  styleUrls: ['./edit-profile.component.css']
})
export class EditProfileComponent implements OnInit {

  userDetail: any = {};
  editForm: FormGroup;
  editImage: any;
  imageUrl: any

  constructor(public router: Router, public service: ServiceService) { }

  ngOnInit() {
    this.myProfile();
  }

  myProfile() {
    this.editForm = new FormGroup({
      'name': new FormControl('', [Validators.required, Validators.pattern(/^[a-zA-Z ]*$/i)]),
      'lastname': new FormControl('', [Validators.required, Validators.pattern(/^[a-zA-Z ]*$/i)]),
      'city': new FormControl('', [Validators.required, Validators.pattern(/^[a-zA-Z1-9 ]*$/i)]),
      'country': new FormControl('', [Validators.required, Validators.pattern(/^[a-zA-Z1-9 ]*$/i)]),
      'email': new FormControl(''),
      'phone': new FormControl(''),
      // 'email': new FormControl('', [Validators.required,Validators.pattern(/^([a-zA-Z0-9_\-\.]+)@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.)|(([a-zA-Z0-9\-]+\.)+))([a-zA-Z]{2,9}|[0-9]{1,3})(\]?)$/i)]),
      // 'phone': new FormControl('', [Validators.required,Validators.pattern(/^[+]*[(]{0,1}[0-9]{1,4}[)]{0,1}[-\s\./0-9]*$/)]),
      'address': new FormControl('', Validators.required)
    })
    var url = 'admin/adminProfile';
    this.service.showSpinner();
    this.service.getApi(url, 1).subscribe(res => {
      // this.service.hideSpinner();
      if (res['responseCode'] == 200) {
        this.userDetail = res['result'];
        this.editImage = this.userDetail.imageUrl;
        this.editForm.patchValue({
          'name': this.userDetail.firstName,
          'lastname': this.userDetail.lastName,
          'email': this.userDetail.email,
          'phone': this.userDetail.mobileNumber,
          'address': this.userDetail.address,
          'city': this.userDetail.city,
          'country': this.userDetail.country
        })
        this.imageUrl = this.userDetail.profilePic
        this.service.hideSpinner();
      } else {
        this.service.hideSpinner();
        this.service.toasterErr(res['responseMessage']);
      }
    }, err => {
      if (err['status'] == '401') {
        this.service.onLogout();
        this.service.toasterErr('Unauthorized Access');
      } else {
        this.service.toasterErr('Something Went Wrong');
      }
      // this.service.hideSpinner();
    })
  }

  // Image Functionality Start Here
  uploadImg($event): void {
    var img = $event.target.files[0];
    this.uploadImageFunc(img);
  }

  async uploadImg1($event) {
    this.imageUrl = await this.service.getBase64($event.target.files[0]);
  }

  uploadImageFunc(img) {
    var fb = new FormData();
    fb.append('file', img)
    this.service.showSpinner();
    this.service.postApi('account/upload-file', fb).subscribe(res => {
      // this.service.hideSpinner();
      if (res['status'] == '200') {
        this.editImage = res['data'];
        this.service.hideSpinner();
      }
    }, err => {
      this.service.hideSpinner();
      if (err['status'] == '401') {
        this.service.onLogout();
        this.service.toasterErr('Unauthorized Access');
      } else {
        this.service.toasterErr('Something Went Wrong');
      }
    })
  }


  updateProfile() {

    var apiReq = {
      "firstName": this.editForm.value.name,
      "lastName": this.editForm.value.lastname,
      // "address": this.editForm.value.address,
      "email": this.editForm.value.email,
      "mobileNumber": this.editForm.value.phone,
      "city": this.editForm.value.city,
      "country": this.editForm.value.country,
      // "imageUrl": this.editImage ? this.editImage : this.userDetail.imageUrl
      "profilePic": this.imageUrl
      // "gender": this.editForm.value.gender,
    }
    this.service.showSpinner();
    this.service.putApi('admin/updateAdminProfile', apiReq, 1).subscribe(res => {
      // this.service.hideSpinner();
      if (res['responseCode'] == 200) {
        // this.myProfile();
        this.service.changeLoginSub('login')
        this.service.toasterSucc('Profile Updated Successfully');
        this.router.navigate(['/my-profile'])
      } else {
        this.service.hideSpinner();
        this.service.toasterErr(res['responseMessage']);
      }
    }, err => {
      this.service.hideSpinner();
      if (err['responseCode'] == '401') {
        this.service.onLogout();
        this.service.toasterErr('Unauthorized Access');
      } else {
        this.service.toasterErr('Something Went Wrong');
      }
    })
  }

  preventSpace(event) {
    if (event.charCode == 32 && !event.target.value) {
      event.preventDefault()
    }
  }

}
