import { ServiceService } from './../../service.service';
import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-manage-fee',
  templateUrl: './manage-fee.component.html',
  styleUrls: ['./manage-fee.component.css']
})
export class ManageFeeComponent implements OnInit {
  currTab: any = ""
  constructor(public service: ServiceService) { }
  tradingDetail: any = []
  ngOnInit(): void {
    this.selectTab('MIN_WITHDRAWAL');
    this.totalReferralAndTotalCount();
  }
  selectTab(tab) {
    this.currTab = tab
  }

  counts: any
  totalReferralAndTotalCount() {
    let url = `admin/referralCountReferralAmount`
    this.service.getApi(url, 1).subscribe(res => {
      if (res.responseCode == 200) {
        this.counts = res.result
      } else {
        this.service.toasterErr(res.responseMessage)
      }
    }, err => {
      this.service.toasterErr(err.error.responseMessage)
    })
  }

}
