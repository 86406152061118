import { Component, OnInit } from '@angular/core';
import { FormGroup, FormControl, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { ServiceService } from 'src/app/service.service';
@Component({
  selector: 'app-edit-team',
  templateUrl: './edit-team.component.html',
  styleUrls: ['./edit-team.component.css']
})
export class EditTeamComponent implements OnInit {

  addSwatchesForm: FormGroup
  imageUrl
  teamId: any;
  teamImage
  editData: any;
  itemPerPage = 10;
  currentPage = 1;
  totalItems: any;
  // teamId: any
  colorListArray: any = []
  showImageField: any = [];
  constructor(private router: Router, public commonService: ServiceService, public active: ActivatedRoute) {
    this.active.queryParams.subscribe((res: any) => {
      this.teamId = res.teamId;


    })
  }

  ngOnInit() {
    this.addSwatchesFormValidation();
    this.viewBlog()
  }
  addSwatchesFormValidation() {
    this.addSwatchesForm = new FormGroup({
      // 'colorName': new FormControl('', [Validators.required]),
      // 'author': new FormControl('', [Validators.required, Validators.pattern(/^[A-Za-z][A-Za-z ]*$/)]),
      'name': new FormControl('', [Validators.required, Validators.pattern(/^[A-Za-z][A-Za-z ]*$/)]),
      'title': new FormControl('', [Validators.required, Validators.pattern(/^[A-Za-z][A-Za-z ]*$/)]),
      'description': new FormControl(''),
      'twitterurl': new FormControl(''),
      'linkedinUrl': new FormControl(''),
      'instagramUrl': new FormControl(''),
      'image': new FormControl('')

      // 'description': new FormControl('',[Validators.required]),
      // 'file': new FormControl(''),

    })
  }





  addSwatches() {

    // this.router.navigate(['/swatches/list-swatches'])
    let url = `static/career-details-update`
    let data = {
      // 'author': this.addSwatchesForm.value.author,
      'teamImage': this.imageUrl,
      'teamName': this.addSwatchesForm.value.name,
      'teamTitle': this.addSwatchesForm.value.title,
      'teamDescription': this.addSwatchesForm.value.description,
      'linkedLnUrl': this.addSwatchesForm.value.linkedinUrl,
      'istagramUrl': this.addSwatchesForm.value.instagramUrl,
      'twitterUrl': this.addSwatchesForm.value.twitterurl,
      'teamId': this.teamId
      // 'quantity': this.addSwatchesForm.value.quantity,
    }
    this.commonService.showSpinner();
    this.commonService.post(url, data).subscribe((res: any) => {
      console.log("add category response ==>", res)
      if (res.status == 200) {
        this.commonService.toasterSucc(res.message);
        this.router.navigate(['/list-team'])
      } else {
        this.commonService.hideSpinner();
        this.commonService.toasterErr(res.message)
      }
    })
  }


  // edit faq english
  viewBlog() {
    this.commonService.showSpinner()
    this.commonService.get("static/get-details-by-teamId?teamId=" + this.teamId).subscribe((res: any) => {
      if (res.status == 200) {
        console.log('jjh', res);
        this.editData = res.data[0];
        this.commonService.hideSpinner()
        this.commonService.toasterSucc(res.message);
        setTimeout(() => {
          this.addSwatchesForm.patchValue({
            title: this.editData.teamTitle,
            name: this.editData.teamName,
            description: this.editData.teamDescription,
            linkedinUrl: this.editData.linkedLnUrl,
            instagramUrl: this.editData.istagramUrl,
            twitterurl: this.editData.twitterUrl,


            // image: this.editData.teamImage
          })
        }, 500);

        this.imageUrl = this.editData.teamImage

      }
    }, err => {

      this.commonService.hideSpinner();
      if (err['status'] == '401') {
        //  this.commonService.onLogout();
        this.commonService.toasterErr('Unauthorized Access');
      } else {
        this.commonService.toasterErr('Something Went Wrong');
      }
    })

  }


  // Image Functionality Start Here
  uploadImg($event): void {
    var img = $event.target.files[0];
    this.uploadImageFunc(img);
  }
  uploadImageFunc(img) {
    var fb = new FormData();
    fb.append('file', img)
    this.commonService.showSpinner();
    this.commonService.postApi('account/upload-file', fb).subscribe(res => {
      // this.commonService.hideSpinner();
      if (res['status'] == '200') {
        this.imageUrl = res['data'];
        this.commonService.hideSpinner();
      }
    }, err => {
      this.commonService.hideSpinner();
      if (err['status'] == '401') {
        this.commonService.onLogout();
        this.commonService.toasterErr('Unauthorized Access');
      } else {
        this.commonService.toasterErr('Something Went Wrong');
      }
    })
  }


}
