import { Component, OnInit } from '@angular/core';
import { FormGroup, FormControl, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { ServiceService } from 'src/app/service.service';

@Component({
  selector: 'app-edit-banner',
  templateUrl: './edit-banner.component.html',
  styleUrls: ['./edit-banner.component.css']
})
export class EditBannerComponent implements OnInit {

  addSwatchesForm: FormGroup
  imageUrl
  bannerId: any;
  editData: any;
  itemPerPage = 10;
  currentPage = 1;
  totalItems: any;
  colorListArray: any = []
  showImageField: any = [];
  // bannerId: any;
  constructor(private router: Router, public commonService: ServiceService, public active: ActivatedRoute) {
    this.active.queryParams.subscribe((res: any) => {
      this.bannerId = res.bannerId;


    })
  }

  ngOnInit() {
    this.addSwatchesFormValidation();
    this.viewBlog()
  }
  addSwatchesFormValidation() {
    this.addSwatchesForm = new FormGroup({
      // 'colorName': new FormControl('', [Validators.required]),
      // 'author': new FormControl('', [Validators.required, Validators.pattern(/^[A-Za-z][A-Za-z ]*$/)]),
      'title': new FormControl('', [Validators.required, Validators.pattern(/^[A-Za-z][A-Za-z ]*$/)]),
      'description': new FormControl('', [Validators.required]),
      'image': new FormControl('')

      // 'description': new FormControl('',[Validators.required]),
      // 'file': new FormControl(''),

    })
  }





  // addSwatches() {
  //   let url = `admin/editBanner`
  //   let data = {
  //     'bannerId': this.bannerId,
  //     'description': this.addSwatchesForm.value.description,
  //     'mediaUrl': this.imageUrl,
  //     'title': this.addSwatchesForm.value.title,
  //   }
  //   this.commonService.putApi(url, data, 1).subscribe((res: any) => {
  //     console.log("add category response ==>", res)
  //     if (res.responseCode == 200) {
  //       this.commonService.toasterSucc(res.responseMessage);
  //       this.router.navigate(['/list-banner'])
  //     } else {
  //       this.commonService.hideSpinner();
  //       this.commonService.toasterErr(res.responseMessage)
  //     }
  //   })
  // }

  addSwatches() {
    let url = `admin/editBanner`
    // let data = {
    //   'tutorialId': this.bannerId,
    //   'description': this.addSwatchesForm.value.description,
    //   'title': this.addSwatchesForm.value.title,
    //   'mediaUrl': this.imgfd
    // }

    let fb = new FormData()
    fb.append('title', this.addSwatchesForm.value.title)
    fb.append('description', this.addSwatchesForm.value.description)
    fb.append('bannerId', this.bannerId)
    fb.append('mediaUrl', this.imgg)

    this.commonService.showSpinner();
    this.commonService.putFormApi(url, fb, 1).subscribe((res: any) => {
      console.log("add category response ==>", res)
      if (res.responseCode == 200) {
        this.commonService.hideSpinner();
        this.commonService.toasterSucc(res.responseMessage);
        this.router.navigate(['/list-banner'])
      } else {
        this.commonService.hideSpinner();
        this.commonService.toasterErr(res.responseMessage)
      }
    })
  }

  imgg: any
  uploadImgg1($event): void {
    this.imgg = $event.target.files[0];
  }


  // edit faq english
  // viewBlog() {
  //   this.commonService.showSpinner()
  //   this.commonService.get("static/get-banner-by-id?bannerId=" + this.bannerId).subscribe((res: any) => {
  //     if (res.status == 200) {
  //       console.log('jjh', res);
  //       this.editData = res.data;
  //       this.commonService.hideSpinner()
  //       //  this.service.toasterSucc(res.message);
  //       this.addSwatchesForm.patchValue({
  //         'description': this.editData.description
  //       })
  //       this.imageUrl = this.editData.imageUrl

  //       //  this.route.navigate(['/faq-management'])
  //     }
  //   }, err => {

  //     this.commonService.hideSpinner();
  //     if (err['status'] == '401') {
  //       //  this.commonService.onLogout();
  //       this.commonService.toasterErr('Unauthorized Access');
  //     } else {
  //       this.commonService.toasterErr('Something Went Wrong');
  //     }
  //   })

  // }

  viewBlog() {
    this.commonService.showSpinner()
    this.commonService.get("admin/viewBanner?bannerId=" + this.bannerId).subscribe((res: any) => {
      if (res.responseCode = 200) {
        this.editData = res.result;
        this.commonService.hideSpinner()
        this.addSwatchesForm.patchValue({
          'description': this.editData.description,
          'title': this.editData.title
        })
        this.imageUrl = this.editData.mediaUrl

      }
    }, err => {

      this.commonService.hideSpinner();
      if (err['responseCode'] == '401') {
        //  this.commonService.onLogout();
        this.commonService.toasterErr('Unauthorized Access');
      } else {
        this.commonService.toasterErr('Something Went Wrong');
      }
    })

  }


  async uploadImg1($event) {
    this.imageUrl = await this.commonService.getBase64($event.target.files[0]);
  }


  // Image Functionality Start Here
  uploadImg($event): void {
    var img = $event.target.files[0];
    this.uploadImageFunc(img);
  }
  uploadImageFunc(img) {
    var fb = new FormData();
    fb.append('file', img)
    this.commonService.showSpinner();
    this.commonService.postApi('account/upload-file', fb).subscribe(res => {
      // this.commonService.hideSpinner();
      if (res['status'] == '200') {
        this.imageUrl = res['data'];
        this.commonService.hideSpinner();
      }
    }, err => {
      this.commonService.hideSpinner();
      if (err['status'] == '401') {
        this.commonService.onLogout();
        this.commonService.toasterErr('Unauthorized Access');
      } else {
        this.commonService.toasterErr('Something Went Wrong');
      }
    })
  }

}
