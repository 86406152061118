import { Component, OnInit } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { ServiceService } from '../service.service';

@Component({
  selector: 'app-tutorial-add',
  templateUrl: './tutorial-add.component.html',
  styleUrls: ['./tutorial-add.component.css']
})
export class TutorialAddComponent implements OnInit {

  addSwatchesForm: FormGroup
  imageUrl

  itemPerPage = 10;
  currentPage = 1;
  totalItems: any;
  colorListArray: any = []
  showImageField: any = [];
  constructor(private router: Router, public commonService: ServiceService) { }

  ngOnInit() {
    this.addSwatchesFormValidation();

  }
  addSwatchesFormValidation() {
    this.addSwatchesForm = new FormGroup({
      'description': new FormControl('', [Validators.required, Validators.pattern(/^[A-Za-z][A-Za-z ]*$/)]),
      'image': new FormControl(''),
      // 'colorName': new FormControl('', [Validators.required]),
      // 'author': new FormControl('', [Validators.required, Validators.pattern(/^[A-Za-z][A-Za-z ]*$/)]),
      'title': new FormControl('', [Validators.required, Validators.pattern(/^[A-Za-z][A-Za-z ]*$/)]),


      // 'description': new FormControl('',[Validators.required]),
      // 'file': new FormControl(''),

    })
  }





  addSwatches() {

    let url = `admin/addTutorial`

    let fb = new FormData()
    fb.append('title', this.addSwatchesForm.value.title)
    fb.append('description', this.addSwatchesForm.value.description)
    fb.append('mediaUrl', this.imgg)

    this.commonService.showSpinner();
    this.commonService.postFormDataApi(url, fb, 1).subscribe((res: any) => {
      if (res.responseCode == 200) {
        this.commonService.toasterSucc(res.responseMessage);
        this.router.navigate(['/tutorial-list'])
      } else {
        this.commonService.hideSpinner();
        this.commonService.toasterErr(res.responseMessage)
      }
    }, err => {
      this.commonService.hideSpinner();
      this.commonService.toasterErr(err.error.responseMessage)
    })
  }

  // getFileType(url) {
  //   return url.substring(url.lastIndexOf("."))
  // }


  // async uploadImg1($event) {
  //   this.imageUrl = await this.commonService.getBase64($event.target.files[0]);
  // }


  fb: any
  imgg: any
  uploadImg($event): void {
    this.imgg = $event.target.files[0];

  }
  uploadImageFunc(img) {
    var fb = new FormData();
    fb.append('file', img)
    this.commonService.showSpinner();
    this.commonService.postFormDataApi('admin/addTutorial', fb, 1).subscribe(res => {
      // this.commonService.hideSpinner();
      if (res['responseCode'] == '200') {
        this.imageUrl = res['result'];
        this.commonService.hideSpinner();
      }
    }, err => {
      this.commonService.hideSpinner();
      if (err['responseCode'] == '401') {
        this.commonService.onLogout();
        this.commonService.toasterErr('Unauthorized Access');
      } else {
        this.commonService.toasterErr('Something Went Wrong');
      }
    })
  }


}
