import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { ServiceService } from '../service.service';


@Component({
  selector: 'app-user-deatils-nominee',
  templateUrl: './user-deatils-nominee.component.html',
  styleUrls: ['./user-deatils-nominee.component.css']
})
export class UserDeatilsNomineeComponent implements OnInit {
  nomineeId: any;
  skirtListArray: any;
  colorList : any = [];
  editData:any
  constructor(private activatedroute: ActivatedRoute, public commonService: ServiceService) { }

  ngOnInit() {
    this.activatedroute.queryParams.subscribe((res) => {
      this.nomineeId = res.nomineeId;
    })
    this.viewNominee()
  }

  profileDataView:any
// view nominee
viewNominee() {
  let url = `account/get-details-by-nomineeId?nomineeId=${this.nomineeId}`
  this.commonService.showSpinner();
  // this.service.getCandyPixelForms(`account/get-details-by-userId`).subscribe(
    this.commonService.get(url).subscribe(
    (res) => {
      if (res["status"] == 200) {
        this.profileDataView = res["data" ];
          this.commonService.hideSpinner();
      } else {
        this.commonService.hideSpinner();
        this.commonService.toasterErr(res["message"]);
      }
    },
    (err) => {
      this.commonService.hideSpinner();
      this.commonService.toasterErr(err['message']);
    }
  );
}

}
