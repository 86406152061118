import { Component, OnInit } from '@angular/core';
import { ServiceService } from '../service.service';
import { ActivatedRoute, Router } from '@angular/router';
import { FormGroup, FormControl } from '@angular/forms';
import { isNgTemplate } from '@angular/compiler';


@Component({
  selector: 'app-user-details',
  templateUrl: './user-details.component.html',
  styleUrls: ['./user-details.component.css']
})
export class UserDetailsComponent implements OnInit {
  userDetail: any;
  profile = ''
  pageNumber: number = 1;
  stakingPageNumber: any = 1;
  p2pPageNumber: any = 1
  pageSize: any = 10
  userID: any;

  userId: any = [];
  currTab: any = 'general';
  currTabb: any = 'BTC';

  loginDetails: any = [];
  email: any;
  tradingForm: FormGroup;
  stakingForm: FormGroup;
  tradingList: any = [];
  tradingListlength: any;
  viewTrading: boolean = false;
  viewTradingg: boolean = false;
  viewEth: boolean = false;
  viewXrp: boolean = false;
  viewLtc: boolean = false;
  walletDetailsList: any = [];
  walletDetailsListLength: any;
  coinList: any = [];
  coinlist: any;
  coinListArr: any = [];
  tradingCurrency: any = ''
  tradingStatus: any = ''
  fromDate: any = ''
  twoDate: any = ''
  calender: any = { todate: this.twoDate, fromdate: this.fromDate }
  minAge: Date;
  totalItems: number;
  tradestatus: any;
  y: any;
  tab: any = ""
  mainTable: boolean = true
  tab1: any
  peerId: any
  mainTable1: boolean = true
  copyToClipboard(item) {
    this.service.toasterSucc("Copied Successfully")
    document.addEventListener('copy', (e: ClipboardEvent) => {
      e.clipboardData.setData('text/plain', (item));
      e.preventDefault();
      document.removeEventListener('copy', null);
    });
    document.execCommand('copy');
  }
  constructor(private router: Router, public service: ServiceService, private route: ActivatedRoute) { }

  ngOnInit() {

    this.y = this.tradingCurrency
    var today = new Date();
    var minAge = 0;
    this.minAge = new Date(today.getFullYear() - minAge, today.getMonth(), today.getDate());
    this.fromdate()
    this.tradingForm = new FormGroup({
      // tradingstatus: new FormControl(''),
      // tradingCurrency: new FormControl(''),
      tradingfromDate: new FormControl(''),
      tradingtoDate: new FormControl(''),
      tradingStatus: new FormControl(''),
    })

    let obj = this.route.queryParams.subscribe(params => {

      this.userId = (params['userId']); // (+) converts string 'id' to a number
      this.email = (params['email']);

      console.log(this.userId);

      localStorage.setItem('userId', this.userId)
    });
    this.viewDetail();
    this.viewCountAmount();
    // this.getCoinWalletList();

  }
  allData: any
  viewData(a, id, allData) {
    this.tab = a
    this.allData = allData
    console.log(this.allData);

    // this.viewByStakingId(id)
    this.mainTable = false;
  }

  return() {
    this.tab = " "
    this.stakingDataByUserId()
    this.mainTable = true;
  }

  p2pView(one, peerId) {
    this.tab1 = one
    console.log(this.tab1);

    // this.viewP2P(peerId);
    this.mainTable1 = false;
  }

  returnP2PList() {
    this.tab1 = ""
    this.mainTable1 = true;
  }

  fromdate() {
    this.fromDate = new Date(this.calender.fromdate)
    this.fromDate = this.fromDate.getTime()
  }
  todate() {
    this.twoDate = new Date(this.calender.todate)
    this.twoDate = this.twoDate.getTime()
  }
  viewDataNew: any
  viewByStakingId(id) {
    let url = `wallet/view-by-id-fixed-deposit?fixedDepositId=${id}`
    this.service.get(url).subscribe((res: any) => {
      this.service.hideSpinner()
      if (res.status == 200) {
        this.viewDataNew = res.data;
        console.log(this.viewDataNew);

      }
      else {
        this.service.hideSpinner();
      }
    }, (error) => {
      this.service.hideSpinner();
    })
  }
  viewP2PDate: any
  viewP2P(peerId) {
    let url = `p2p-exchange/search-and-filters-advertisement?peerToPeerExchangeId=${peerId}`
    this.service.get(url).subscribe((res: any) => {
      this.service.hideSpinner()
      if (res.status == 200) {
        this.viewP2PDate = res.data.list[0];
        console.log(this.viewP2PDate);

      }
      else {
        this.service.hideSpinner();
      }
    }, (error) => {
      this.service.hideSpinner();
    })
  }

  type: any = ""
  selectTab(tab, type) {
    this.type = type
    this.currTab = tab;
    if (this.currTab == 'wallet') {
      this.walletDetails();
    } else if (this.currTab == 'login') {
      this.logDetails();
    } else if (this.currTab == 'p2p') {
      this.p2pDataByuserId()
    } if (this.currTab == 'staking') {
      this.stakingDataByUserId();
    }
  }

  selectTabb(coin) {
    this.walletDetailss(coin.target.value)
  }

  performAction() { }
  // walletDetails() {
  //   this.service.showSpinner();
  //   this.service.get('wallet/get-all-transaction-history-USER?coinName=' + (this.currTabb) + '&page=' + (0) + '&pageSize=' + (10) + '&fkUserId=' + (this.userId)).subscribe((res: any) => {
  //     this.service.hideSpinner()
  //     if (res.status == 200) {
  //       this.walletDetailsList = res.data.resultlist;
  //       this.walletDetailsListLength = res.data.totalCount;
  //     }
  //     else {
  //       this.service.hideSpinner();
  //     }
  //   }, (error) => {
  //     this.service.hideSpinner();
  //   })
  // }
  walletDetails() {
    this.service.showSpinner();
    this.service.getApi('admin/P2PStakingLogHistoryListbyUser?userId=' + (this.userId) + '&type=' + (this.type), 0).subscribe((res: any) => {
      this.service.hideSpinner()
      if (res.responseCode == 200) {
        this.walletDetailsList = res.result.docs;
        this.walletDetailsListLength = res.result.total;
      }
      else {
        this.service.hideSpinner();
      }
    }, (error) => {
      this.service.hideSpinner();
    })
  }
  login: any
  logDetails() {
    this.service.showSpinner();
    this.service.getApi('admin/P2PStakingLogHistoryListbyUser?userId=' + (this.userId) + '&type=' + (this.type), 0).subscribe((res: any) => {
      this.service.hideSpinner()
      if (res.responseCode == 200) {
        this.login = res.result.docs;
        this.walletDetailsListLength = res.result.total;
      }
      else {
        this.service.hideSpinner();
      }
    }, (error) => {
      this.service.hideSpinner();
    })
  }

  // getProfile() {
  //   this.service.showSpinner();
  //   this.service.get('account/get-user?email=' + (this.email)).subscribe((res) => {
  //     this.service.hideSpinner();
  //     this.loginDetails = res['data']
  //   })
  // }
  viewCoinDetails(transactionId) {
    this.service.showSpinner();
    this.getCoinDetailsByuserId(transactionId);
    this.viewTradingg = true;
    setTimeout(() => {
      this.service.hideSpinner();
    }, 2000);
  }

  viewCoinBTCDetails(transactionId) {
    this.service.showSpinner();
    this.getCoinDetailsByuserId(transactionId);
    this.viewEth = true;
    setTimeout(() => {
      this.service.hideSpinner();
    }, 2000);
  }

  viewXrpCoinDetails(transactionId) {
    this.service.showSpinner();
    this.getCoinDetailsByuserId(transactionId);
    this.viewXrp = true;
    setTimeout(() => {
      this.service.hideSpinner();
    }, 2000);
  }

  viewLtcCoinDetails(transactionId) {
    this.service.showSpinner();
    this.getCoinDetailsByuserId(transactionId);
    this.viewLtc = true;
    setTimeout(() => {
      this.service.hideSpinner();
    }, 2000);
  }

  nomineeListArray: any = []
  // get nominee by id
  getNominee() {
    this.service.showSpinner();
    // this.service.getCandyPixelForms(`account/get-details-by-userId`).subscribe(
    this.service.get(`account/get-details-by-userId`).subscribe(
      (res) => {
        if (res["status"] == 200) {
          this.nomineeListArray = res["data"];
          this.service.hideSpinner();

        } else {
          this.service.hideSpinner();
          this.service.toasterErr(res["message"]);
        }
      },
      (err) => {
        this.service.hideSpinner();
        this.service.toasterErr(err['message']);
      }
    );
  }
  viewNominee(subTeamId) {
    // alert(subTeamId)
    this.router.navigate(['/nominee-view'], { queryParams: { subTeamId: subTeamId } })
  }

  // 'wallet/coin/get-coin-list'

  // getCoinWalletList() {
  //   this.service.showSpinner();
  //   this.service.get('wallet/coin/get-coin-list').subscribe((res: any) => {
  //     if (res.status == 200) {
  //       this.coinlist = res.data;

  //       this.coinlist.forEach(element => {
  //         this.coinListArr.push(element.coinShortName);
  //         let removeCoin = ["BCH", "OMG", "XLM", "DASH", "LTC", "INR"];
  //         this.coinList = this.coinList.filter((ele) => {
  //           return !removeCoin.includes(ele.coinShortName);
  //         });
  //       });
  //       this.service.hideSpinner();

  //     }
  //     else {
  //       this.service.hideSpinner();
  //     }
  //   }, (error) => {
  //     this.service.hideSpinner();
  //   })
  // }

  getCoinDetailsByuserId(transactionId) {
    // http://182.72.203.244:3062/wallet/admin/transaction-history/get-transaction-details?txnId=1
    this.service.get('wallet/get-transaction-details?txnId=' + (transactionId)).subscribe((res: any) => {
      if (res.status == 200) {
        this.coinList = res.data;
        // this.tradingListlength = res.data.length;
      }
    })
  }

  viewTradingDetails(userId, transactionId) {
    this.service.showSpinner();
    // this.getTradingHistoryByuserId(userId, transactionId);
    this.viewTrading = true;
    setTimeout(() => {
      this.service.hideSpinner();
    }, 2000)
  }
  // getTradingHistoryByuserId(userId, transactionId) {
  //   let data = {
  //     "userId": this.userId,
  //     "transactionId": transactionId
  //   }
  //   this.service.post('order-service/get-trade-history', data).subscribe((res: any) => {
  //     if (res.status == 200) {
  //       this.tradingList = res.data[0];
  //       // this.tradingListlength = res.data.length;
  //     }
  //   })
  // }
  backk() {
    this.viewTrading = false;
    this.viewDetail();
    // this.getProfile();
    // this.getTradingHistory();
    this.walletDetails();
  }
  backkk() {
    this.viewTradingg = false;
    this.viewDetail();
    // this.getProfile();
    // this.getTradingHistory();
    this.walletDetails();
  }
  backfromEth() {
    this.viewEth = false;
    // this.viewXrp = false;
    // this.viewLtc = false;
    this.viewDetail();
    // this.getProfile();
    // this.getTradingHistory();
    this.walletDetails();
  }

  backfromXrp() {
    this.viewXrp = false;
    // this.viewLtc = false;
    this.viewDetail();
    // this.getProfile();
    // this.getTradingHistory();
    this.walletDetails();
  }

  backfromLtc() {
    this.viewLtc = false;
    this.viewDetail();
    // this.getProfile();
    // this.getTradingHistory();
    this.walletDetails();
  }
  pagination(page) {
    this.pageNumber = page;
    this.walletDetails();
  }
  stakingPage(page) {
    this.stakingPageNumber = page
  }

  // User Detail

  viewDetail() {
    var url = 'admin/viewUser?_id=' + this.userId;
    // this.service.showSpinner();
    this.service.getApi(url, 1).subscribe((res: any) => {
      this.service.hideSpinner();
      if (res['responseCode'] == 200) {
        // this.getProfile();
        // this.getTradingHistory();
        // this.walletDetails();
        this.userDetail = res.result
        this.profile = this.userDetail.profilePic
      } else {
        this.service.hideSpinner();
        this.service.toasterErr(res['responseMessage']);
      }
    }, err => {
      this.service.hideSpinner();
      if (err['responseCode'] == '401') {
        this.service.onLogout();
        this.service.toasterErr('Unauthorized Access');
      } else {
        this.service.toasterErr('Something Went Wrong');
      }
    })
  }
  // userDetail1: any
  // viewAllDetail() {
  //   var url = 'admin/P2PStakingLogHistoryListbyUser?userId=' + this.userId + "&type=" + this.type;
  //   this.service.showSpinner();
  //   this.service.getApi(url, 1).subscribe((res: any) => {
  //     this.service.hideSpinner();
  //     if (res['responseCode'] == 200) {
  //       this.getProfile();
  //       this.walletDetails();
  //       this.userDetail1 = res.result

  //     } else {
  //       this.service.hideSpinner();
  //       this.service.toasterErr(res['responseMessage']);
  //     }
  //   }, err => {
  //     this.service.hideSpinner();
  //     if (err['responseCode'] == '401') {
  //       this.service.onLogout();
  //       this.service.toasterErr('Unauthorized Access');
  //     } else {
  //       this.service.toasterErr('Something Went Wrong');
  //     }
  //   })
  // }

  back() {
    this.router.navigate(['user-management/'])
  }

  /** to switch between tabs */

  walletDetailss(data) {
    this.service.showSpinner();
    this.service.get('wallet/get-all-transaction-history?coinName=' + (data) + '&page=' + (0) + '&pageSize=' + (10) + '&fkUserId=' + (this.userId)).subscribe((res: any) => {
      this.service.hideSpinner();
      if (res.status == 200) {
        this.walletDetailsList = res.data.resultlist;
        this.walletDetailsListLength = res.data.totalCount;
      }
      else {
        this.service.hideSpinner();
      }
    }, (error) => {
      this.service.hideSpinner();
    })
  }
  //order-service/get-trade-historypage=${this.currentPage - 1}&pageSize=${this.itemsPerPage}${(this.userId2 ? ('&userId2=' + this.userId2) : '') + (this.userName ? ('&userName=' + this.userName) : '') +
  // (this.fromDate ? ('&fromDate=' + this.fromDate) : '') + (this.twoDate ? ('&toDate=' + this.twoDate) : '') + (this.orderStatus ? ('&orderStatus=' + this.orderStatus) : '') + (this.country ? ('&country=' + this.country) : '') +
  // (this.paymentType ? ('&paymentType=' + this.paymentType) : '') + (this.orderType ? ('&orderType=' + this.orderType) : '')}'

  // getTradingHistory() {
  //   let data = {
  //     "userId": this.userId,
  //     "baseCoin": this.y,
  //     "status":this.tradestatus
  //       }
  //   this.service.post("order-service/get-trade-history", {}).subscribe((res: any) => {

  //     if (res.status == 200) {
  //       this.tradingList = res.data;
  //       this.tradingListlength = res.data.length;
  //     }
  //   })
  // }

  reverseArr() {
    let arr = [1, 2, 3, 4, 5, 6, 7];
    let newArr = [];
    let i;
    for (arr.length - 1; i >= 0; i--) {
      newArr.push(arr[i])
    }

  }

  // searchTrading(){
  //   this.service.showSpinner();
  //   let searchAndFilterDto  = {

  //   "baseCoin": this.tradingCurrency?this.tradingCurrency:null,
  //   // "exeCoin": "string",
  // "fromDate": this.fromDate?this.fromDate:null,
  //   "side": this.tradingStatus?this.tradingStatus:null,
  //  "toDate": this.twoDate?this.twoDate:null,
  // //  "transactionId": 0,
  //   "userId": this.userId
  //            }
  //        this.service.post("order-service/get-trade-history", searchAndFilterDto).subscribe((res: any) => {
  //         this.service.hideSpinner();
  //          if (res.status == 200) {
  //            this.tradingList = res.data;
  //            this.tradingListlength = res.data.length;
  //            this.service.toasterSucc('Success')


  //          }
  //        else {
  //           this.service.hideSpinner();
  //          }
  //        }, (error) => {
  //         this.service.hideSpinner();
  //       })



  // }

  reset() {
    // this.getTradingHistory();
    this.tradingList = [];
    this.tradingListlength = 0
    this.pageNumber = 1;

    if (this.fromDate || this.twoDate || this.tradingStatus || this.tradingCurrency) {

      this.fromDate = ''
      this.twoDate = ''
      this.calender = { todate: '', fromdate: '' }
      this.tradingCurrency = ''
      this.tradingStatus = ''

      /*   this.tradingForm.value.tradingstatus='' */
    }
    else {

    }


  }

  exportAsXLSX() {
    let dataArr = [];
    this.tradingList.forEach((element, ind) => {

      dataArr.push({
        "S No": ind + 1,
        "Transaction ID": element.transactionId ? element.transactionId : 'N/A',
        "Transaction Type": element.orderSide ? element.orderSide : 'N/A',
        "Amount": element.quantity ? element.quantity : 'N/A',
        "Base Coin": element.baseCoin ? element.baseCoin : 'N/A',
        "Executable Coin": element.exeCoin ? element.exeCoin : 'N/A',
        "Date and Time": element.lastExecutionTime ? element.lastExecutionTime.slice(0, 10) : 'N/A',
      })
    })

    this.service.exportAsExcelFile(dataArr, 'Trading list');
  }
  stakingData: any = [];
  stakingCount: any
  stakingDataByUserId() {
    // let url = `wallet/view-by-user-id-fixed-deposit?page=${this.stakingPageNumber - 1}&pageSize=${this.pageSize}&userId=${this.userId}`
    this.service.getApi('admin/P2PStakingLogHistoryListbyUser?userId=' + (this.userId) + '&type=' + (this.type), 0).subscribe((res: any) => {
      this.service.hideSpinner();
      if (res.responseCode == 200) {
        this.stakingData = res.result.docs;
        this.stakingCount = res.result.total
      }
      else {
        this.service.hideSpinner();
      }
    }, (error) => {
      this.service.hideSpinner();
    })
  }
  pairData: any = [];
  pairCount: any
  p2pDataByuserId() {
    // let url = `p2p-exchange/view-p2p-by-user-id?page=${this.p2pPageNumber - 1}&pageSize=${this.pageSize}&userId=${this.userId}`
    this.service.getApi('admin/P2PStakingLogHistoryListbyUser?userId=' + (this.userId) + '&type=' + (this.type), 0).subscribe((res: any) => {
      this.service.hideSpinner();
      if (res.responseCode == 200) {
        this.pairData = res.result.docs;
        this.pairCount = res.result.total
      }
      else {
        this.service.hideSpinner();
      }
    }, (error) => {
      this.service.hideSpinner();
    })
  }

  // By user id user referral count and user referral amount 
  countData: any
  totalearn: any
  totalreferral: any
  viewCountAmount() {
    let url = `admin/userRefferalCountEarning?userId=${this.userId}`
    this.service.getApi(url, 0).subscribe((res) => {
      if (res.responseCode == 200) {
        this.countData = res.result
        this.totalearn = res.result.Totalearning;
        this.totalreferral = res.result.totalRefferalUserShare
        // this.service.toasterSucc(res.responseMessage)
      } else {
        this.service.toasterErr(res.responseMessage);
      }
    }, (err) => {
      this.service.toasterErr(err.error.responseMessage)
    })
  }


}
