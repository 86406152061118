import { Component, OnInit } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { ServiceService } from 'src/app/service.service';

@Component({
  selector: 'app-add-aip-percent',
  templateUrl: './add-aip-percent.component.html',
  styleUrls: ['./add-aip-percent.component.css']
})
export class AddAipPercentComponent implements OnInit {


  addSwatchesForm: FormGroup
  imageUrl

  itemPerPage = 10;
  currentPage = 1;
  totalItems: any;
  colorListArray: any = []
  showImageField: any = [];
  planId: any;

  aipPerList: any = [];
  flag : boolean = false
  max = 80
  constructor(private router: Router, private activatedRoute: ActivatedRoute, public commonService: ServiceService) {
    this.activatedRoute.queryParams.subscribe((res) => {
      this.planId = res.planId
      this.flag = res.flag
      this.max = res.max
    })
  }

  ngOnInit() {

    this.aipPerPlan();
    this.addSwatchesFormValidation();

    this.getCoinList()

  }
  addSwatchesFormValidation() {
    this.addSwatchesForm = new FormGroup({
      'coin': new FormControl('', [Validators.required, Validators.pattern(/^[A-Za-z][A-Za-z ]*$/)]),
      'percent': new FormControl('', [Validators.required]),

    })
  }
  set_max(me : number){
    console.log(me);
    me  = Number(me)
   if(me > this.max){
    setTimeout(() => {
      this.addSwatchesForm.patchValue({
        percent : this.max
      })
    }, 100);
   }
  }

  coinList: any = []
  getCoinList() {
    let url = `wallet/coin/get-coin-list`
    this.commonService.showSpinner();
    this.commonService.get(url).subscribe((res: any) => {
      console.log("add category response ==>", res)
      if (res.status == 200) {
        this.coinList = res.data
        this.commonService.toasterSucc(res.message);
        this.commonService.hideSpinner();

      } else {
        this.commonService.hideSpinner();
        this.commonService.toasterErr(res.message)
      }
    })
  }
  aipPerPlan() {
    this.commonService.showSpinner();
    this.commonService.get("wallet/view-auto-invest-full-details").subscribe(
      (res: any) => {
        if (res.status == 200) {
          this.aipPerList = res.data;
          this.commonService.hideSpinner();
        } else {
          this.commonService.hideSpinner();
        }
      },
      (error) => {
        this.commonService.hideSpinner();
      }
    );
  }



  addSwatches() {
    console.log(this.addSwatchesForm.value)
    let url = `wallet/CoinPlan?planId=${this.planId}`
    let data = {
      'coinName': this.addSwatchesForm.value.coin,
      'percentage': this.addSwatchesForm.value.percent,
    }
    this.commonService.showSpinner();
    this.commonService.post(url, data).subscribe((res: any) => {
      console.log("add category response ==>", res)
      if (res.status == 200) {
        this.commonService.toasterSucc(res.message);
        this.navigateBack()
      } else {
        this.commonService.hideSpinner();
        this.commonService.toasterErr(res.message)
      }
    })
  }





  // Image Functionality Start Here
  uploadImg($event): void {
    var img = $event.target.files[0];
    this.uploadImageFunc(img);
  }
  uploadImageFunc(img) {
    var fb = new FormData();
    fb.append('file', img)
    this.commonService.showSpinner();
    this.commonService.postApi('account/upload-file', fb).subscribe(res => {
      // this.commonService.hideSpinner();
      if (res['status'] == '200') {
        this.imageUrl = res['data'];
        this.commonService.hideSpinner();
      }
    }, err => {
      this.commonService.hideSpinner();
      if (err['status'] == '401') {
        this.commonService.onLogout();
        this.commonService.toasterErr('Unauthorized Access');
      } else {
        this.commonService.toasterErr('Something Went Wrong');
      }
    })
  }

  navigateBack() {
    if(this.flag){
      this.router.navigate(['/edit-aip-details'], { queryParams: { planId: this.planId } })

    }
    else{
      this.router.navigate(['/view-aip-details'], { queryParams: { planId: this.planId } })

    }
  }
}
