import { Component, OnInit, ViewChild, ElementRef } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { ServiceService } from '../service.service';
import { FormGroup, FormControl, Validators } from '@angular/forms';
declare var $: any;

@Component({
  selector: 'app-dispute-trade-details',
  templateUrl: './dispute-trade-details.component.html',
  styleUrls: ['./dispute-trade-details.component.css']
})
export class DisputeTradeDetailsComponent implements OnInit {
  @ViewChild('scrollMe', { static: true }) private myScrollContainer: ElementRef;
  @ViewChild('scrollMeAdminChat', { static: true }) private myScrollContainerAdminChat: ElementRef

  tradeId: any;
  obj: any = {}
  tradeDetails: any;
  toEmail: string;
  adminData: any;
  adminEmail: any;
  currentDate: Date;
  buyerSeller: any;
  fkUserId: any;
  partnerId: any;
  takeAction: boolean;
  takeDipute: boolean = false;
  recommendedAction: boolean;
  remarkForm: FormGroup;
  chatHistory: any = [];
  disputeId: any;
  adminId: any;
  toUserId: any;
  chatInitAndSubscribe: boolean = false;
  timerEvidence: any = '';
  timerForEvidence: any;
  timerArray: any[] = [3, 6, 12, 24, 48, 72, 96, 120, 144, 168];
  imageSendInChat: any;

  // pagination
  itemsPerPageDispute: any = 5;
  currentPageDispute: any = 1;
  totalItemsDispute: any;
  disputeHistoryArray: any[] = [];
  itemsPerPageTrade: any = 5;
  currentPageTrade: any = 1;
  totalItemsTrade: any;
  tradeHistoryArray: any[] = [];
  chatId: any
  p2pId: any

  constructor(private activatedRoute: ActivatedRoute, private router: Router, public service: ServiceService) {
    this.activatedRoute.params.subscribe((res) => {
      if (res.id) {
        this.tradeId = res.id
        this.chatId = res.chatId
        this.p2pId = res.p2pId
      }
    })
  }

  ngOnInit() {
    // this.adminEmail="mohitcryptocurrency@mailinator.com";
    // this.activatedRoute.params.subscribe((res) => {
    //   if (res.id) {
    //     this.toEmail = 'ds123@mailinator.com'
    //   }
    // })
    // this.scrollToBottom();
    // this.service.supportChatArr = [];
    // // this.adminData = JSON.parse(localStorage.getItem('data'));
    // // this.adminEmail = this.adminData.email;
    // this.adminEmail = localStorage.getItem('adminEmail');
    // this.currentDate = new Date();
    // this.chatHistory();
    // this.service.getMessage().subscribe(res => {
    //   this.scrollToBottom();
    // })
    this.service.chatArr = [];
    this.chatHistory = [];
    this.adminId = localStorage.getItem('userId')
    this.currentDate = new Date();
    this.getTradeDetails();
    this.remarkFormValidation();
  }

  scrollToBottom() {
    setTimeout(() => {
      try {
        this.myScrollContainer.nativeElement.scrollTop = this.myScrollContainer.nativeElement.scrollHeight
      } catch (err) { }
    }, 10)
  }

  // chatHistory() {
  //   this.service.showSpinner()
  //   this.service.get(`notification/get-support-chat-data?toUsername=${this.toEmail}`).subscribe((res) => {
  //     this.service.hideSpinner()
  //     if (res['status'] == 1620) {
  //       let data = res['data']
  //       this.service.supportChatArr = data
  //       this.scrollToBottom()
  //     }
  //   }, err=>{
  //     this.service.hideSpinner()
  //   })
  // }

  getTradeDetails() {
    // this.service.showSpinner();`
    this.service.getApi(`admin/appeal/${this.tradeId}`, 1).subscribe((res) => {
      this.service.hideSpinner();
      if (res['responseCode'] == 200) {
        this.tradeDetails = res['result']
        // this.tradeDetails = res['data'].RESULT_LIST
        // this.buyerSeller = res['data'].RESULT_LIST.type
        // this.disputeId = res['data'].RESULT_LIST.disputeId;
        // this.fkUserId = res['data'].RESULT_LIST.fkUserId;
        // this.partnerId = res['data'].RESULT_LIST.partnerId;
        this.getChatHistory()
      }
    }, err => {
      this.service.hideSpinner()
    })
  }
  // getTradeDetails() {
  //   this.service.showSpinner();
  //   this.service.get(`p2p-exchange/admin/get-trade-details-by-tradeid?tradeId=${this.tradeId}`).subscribe((res) => {
  //     this.service.hideSpinner();
  //     if (res['status'] == 200) {
  //       this.tradeDetails = res['data'].RESULT_LIST
  //       this.buyerSeller = res['data'].RESULT_LIST.type
  //       this.disputeId = res['data'].RESULT_LIST.disputeId;
  //       this.fkUserId = res['data'].RESULT_LIST.fkUserId;
  //       this.partnerId = res['data'].RESULT_LIST.partnerId;
  //       this.getChatHistory()
  //     }
  //   }, err => {
  //     this.service.hideSpinner()
  //   })
  // }

  // getChatHistory() {
  //   let url = `notification/get-chat-history-through-admin?tradeId=${this.tradeId}&adminId=${this.fkUserId}&userId=${this.partnerId}`
  //   this.service.showSpinner();
  //   this.service.get(url).subscribe((res) => {
  //     this.service.hideSpinner();
  //     if (res['status'] == 1620) {
  //       this.chatHistory = res['data']
  //       this.scrollToBottom()
  //     }
  //   }, err => {
  //     this.service.hideSpinner()

  //   })
  // }

  chatHistory1: any
  chatHistory2: any
  tradeStatus: any
  getChatHistory() {
    let url = `admin/chat/${this.chatId}`
    this.service.getApi(url, 1).subscribe((res) => {
      if (res['responseCode'] == 200) {
        // this.service.toasterSucc(res.responseMessage)
        this.chatHistory = res['result']['messages']
        this.chatHistory1 = res['result']['senderId']['_id']
        this.chatHistory2 = res['result']['receiverId']['_id']
        this.tradeStatus = res['result']['lockedAmountP2PId']['tradeStatus']
        console.log(this.chatHistory1);

        this.scrollToBottom()
      }
    }, err => {
      this.service.hideSpinner()

    })
  }

  // openDispute() {
  //   this.service.showSpinner();
  //   this.service.get(`p2p-exchange/after-press-dispute-button?disputeStatus=Raised&tradeId=${this.tradeId}`).subscribe((res) => {
  //     this.service.hideSpinner()
  //     if (res['status'] == 200) {
  //       this.service.toasterSucc(res['message'])
  //       this.router.navigate(['/dispute-management'])
  //     } else {
  //       this.service.toasterErr(res['message'])
  //     }
  //   }, err => {
  //     this.service.toasterErr(err['message'])
  //     this.service.hideSpinner()
  //   })
  // }

  // sendChat() {
  //   if (this.obj.chat) {
  //     let data = {
  //       "fromEmail": this.adminEmail,
  //       "topic": "SUPPORT",
  //       "message": this.obj.chat
  //     }
  //     this.service.wsSupportChat.send(JSON.stringify(data))
  //       this.service.supportChatArr.push(data)
  //     this.obj.chat = ''
  //   }
  // }

  // releaseForDetails() {
  //   this.service.showSpinner();
  //   this.service.post(`/p2p-exchange/admin/release-bitcoins-by-admin?tradeId=${this.tradeId}`,'').subscribe((res) => {
  //     this.service.hideSpinner();
  //     if (res['status'] == 200) {
  //       this.tradeDetails = res['data'].RESULT_LIST
  //       // this.getChatHistory()
  //       this.service.toasterSucc(res['message'])
  //     }
  //     else {
  //       this.service.toasterSucc(res['message'])
  //     }
  //   }, err => {
  //     this.service.hideSpinner()
  //   })
  // }

  // take dispute
  takeDisputeByAdmin() {
    this.takeDipute = true
  }




  /**
   * recommended action taken by admin
   * remark will show whatever observation done by admin/staff
   */
  recommendedActionByAdmin() {
    $('#recommendedActionModal').modal('show')
  }

  remarkFormValidation() {
    this.remarkForm = new FormGroup({
      'remark': new FormControl('', Validators.required)
    })
  }

  recommendedActionByAdminSave() {
    let url = `p2p-exchange/admin/recommendation?disputeId=${this.disputeId}&recommendation=${this.remarkForm.value.remark}`
    this.service.showSpinner();
    this.service.get(url).subscribe((res: any) => {
      if (res['status'] == 200) {
        this.service.hideSpinner();
        this.service.toasterSucc(res.message);
        $('#recommendedActionModal').modal('hide')
        this.remarkForm.reset()
        this.getTradeDetails();
      } else {
        this.service.hideSpinner();
        this.service.toasterErr(res.message);
      }
    }, (error: any) => {
      this.service.hideSpinner();
      this.service.toasterErr(error.message);
    })
  }


  /**
   * admin select the user from whome he want to chat and ask for evidence
   * @param event 
   */
  // select user for chat
  selectUserForChat(event) {
    this.toUserId = event.target.value;
    if (this.chatInitAndSubscribe == false) {
      this.service.initSocketChat() // initialise chat socket
      this.scrollToBottomAdminChat();
      this.chatInitAndSubscribe = true
    }
    this.service.chatArr = [];
    this.timerForEvidence = ''
    this.timerEvidence = ''
    this.obj.chat = ''
    this.chatHistoryAdminAndUser() // chat between admin and buyer/seller
    this.scrollToBottomAdminChat();
    this.service.getMessage().subscribe(res => {
      this.scrollToBottomAdminChat()
    })
  }

  // get chat history of admin and buyer/seller
  chatHistoryAdminAndUser() {
    let url = `notification/get-chat-history-through-admin?tradeId=${this.tradeId}&adminId=${this.adminId}&userId=${this.toUserId}`
    this.service.showSpinner();
    this.service.get(url).subscribe((res) => {
      this.service.hideSpinner();
      if (res['status'] == 1620) {
        this.chatHistory = res['data']
        this.service.chatArr = this.chatHistory

        this.scrollToBottomAdminChat()
      }
    }, err => {
      this.service.hideSpinner()
    })
  }

  // scroll chat to bottom of container
  scrollToBottomAdminChat() {
    setTimeout(() => {
      try {
        this.myScrollContainerAdminChat.nativeElement.scrollTop = this.myScrollContainerAdminChat.nativeElement.scrollHeight
      } catch (err) { }
    }, 10);
  }

  // send chat
  sendChat() {
    if (this.toUserId == '' || this.toUserId == undefined) {
      return this.service.toasterErr('Please select user.')
    }
    else {
      var data;
      if (this.obj.chat) {
        // if(this.chatHistory){
        data = {
          "userId": this.adminId,
          "toUserId": this.toUserId,
          "messageFormat": "TEXT",
          "message": this.obj.chat,
          "tradeId": this.tradeId,
          "timerForEvidence": this.timerForEvidence,
          "role": "ADMIN",
          "returnUrl": `${this.service.websiteURL}admin-chat?tradeId=${this.tradeId}&toUserId=${this.adminId}`
        }
        // }else{
        //  data = {
        //     "userId": this.adminId,
        //     "toUserId": this.toUserId,
        //     "messageFormat": "TEXT",
        //     "message": this.obj.chat,
        //     "tradeId": this.tradeId,
        //     "timerForEvidence": this.timerForEvidence
        //   }
        // }


        data = this.service.removeEmptyKey(data)
        this.service.wsChat.send(JSON.stringify(data))
        this.service.chatArr.push(data)
        this.scrollToBottomAdminChat()
        this.obj.chat = ''
        this.timerForEvidence = ''
      }
    }
  }

  // ask for evidence timer
  timerForEvidenceAsk(event) {
    this.timerForEvidence = event.target.value
    if (this.timerForEvidence == '') {
      return
    }
    this.obj.chat = 'Please submit your evidence within ' + this.timerForEvidence + ' hour'
  }

  // send image in chat
  handleFileInput(event) {
    if (this.toUserId == '' || this.toUserId == undefined) {
      return this.service.toasterErr('Please select user.')
    }
    else {
      if (event.target.files && event.target.files[0]) {
        var type = event.target.files[0].type;
        if (type === 'image/png' || type === 'image/jpg' || type === 'image/jpeg' || type === 'application/pdf') {
          let fileData = event.target.files[0];
          this.sendFormData(fileData)
        } else {
          this.service.toasterErr('Select only jpg, jpeg and png file.');
        }
      }
    }
  }

  sendFormData(fileData) {
    this.service.toasterSucc('Please wait! Upload in progress...');
    let formdata = new FormData()
    formdata.append('file', fileData);
    this.service.post('account/upload-file', formdata).subscribe((res: any) => {
      if (res.status == 200) {
        this.imageSendInChat = res.data
        let data = {
          "userId": this.adminId,
          "toUserId": this.toUserId,
          "messageFormat": "IMAGE",
          "message": this.imageSendInChat,
          // "type": "IMAGE",
          "tradeId": this.tradeId
        }
        this.service.wsChat.send(JSON.stringify(data))
        this.service.chatArr.push(data) // hide due to message repeate
        this.scrollToBottomAdminChat()
        this.imageSendInChat = ''
      }
    })
  }



  /**
   * action taken by admin/staff after the investigation based on chat and evidence
   */
  // action taken by admin/staff
  takeActionByAdmin() {
    $('#takeActionModal').modal('show')
  }

  takeActionByAdmin1() {
    $('#takeActionModal1').modal('show')
  }

  releaseForDetailsModal() {
    $('#takeActionModal').modal('hide')
    $('#releaseEscrowModal').modal('show')
  }

  releaseForDetails() {
    this.service.showSpinner();
    this.service.post(`/p2p-exchange/admin/release-bitcoins-by-admin?tradeId=${this.tradeId}`, '').subscribe((res) => {
      this.service.hideSpinner();
      if (res['status'] == 200) {
        this.tradeDetails = res['data'].RESULT_LIST
        $('#releaseEscrowModal').modal('hide')
        this.service.toasterSucc(res['message'])
      }
      else {
        $('#releaseEscrowModal').modal('hide')
        this.service.toasterSucc(res['message'])
      }
    }, err => {
      this.service.hideSpinner()
    })
  }


  // approve
  takeDecision() {
    let data = {
      p2pAdvertisementId: this.p2pId,
      chatId: this.chatId
    }
    this.service.showSpinner();
    this.service.patchApi(`admin/confirmP2PAdvertisementPayment`, data, 1).subscribe((res) => {
      this.service.hideSpinner();
      if (res['responseCode'] == 200) {
        $('#takeActionModal1').modal('hide')
        this.getChatHistory();
        this.service.toasterSucc(res['responseMessage'])
      }
      else {
        $('#takeActionModal1').modal('hide')
        this.service.toasterSucc(res['responseMessage'])
      }
    }, err => {
      this.service.hideSpinner()
      $('#takeActionModal1').modal('hide')
    })
  }
  // reject

  takeDecisionForReject() {
    let data = {
      p2pAdvertisementId: this.p2pId,
      chatId: this.chatId
    }
    this.service.showSpinner();
    this.service.patchApi(`admin/rejectP2PAdvertisementPayment`, data, 1).subscribe((res) => {
      this.service.hideSpinner();
      if (res['responseCode'] == 200) {
        $('#takeActionModal1').modal('hide')
        this.getChatHistory();
        this.service.toasterSucc(res['responseMessage'])
      }
      else {
        $('#takeActionModal1').modal('hide')
        this.service.toasterSucc(res['responseMessage'])
      }
    }, err => {
      this.service.hideSpinner()
      $('#takeActionModal1').modal('hide')
    })
  }
}



